// UI
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const STORE_AUTHENTICATION_INFO = "STORE_AUTHENTICATION_INFO";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_LOGGING_IN_MESSAGE = "SET_LOGGING_IN_MESSAGE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";
export const LOADING_SIGN_UP_REQUEST = "LOADING_SIGN_UP_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const STORE_NEW_TIMESTAMP = "STORE_NEW_TIMESTAMP";
export const LOADING_ACTIVATE_ACCOUNT_REQUEST =
  "LOADING_ACTIVATE_ACCOUNT_REQUEST";
export const FAILED_ACTIVATE_ACCOUNT_REQUEST =
  "FAILED_ACTIVATE_ACCOUNT_REQUEST";
export const SUCCESS_ACTIVATE_ACCOUNT_REQUEST =
  "SUCCESS_ACTIVATE_ACCOUNT_REQUEST";
export const ACTIVATING_ACCOUNT = "ACTIVATING_ACCOUNT";
export const ACCNT_ACTIVATION_SUCCESS = "ACCNT_ACTIVATION_SUCCESS";
export const ACCNT_ACTIVATION_FAILED = "ACCNT_ACTIVATION_FAILED";
export const ACTIVATION_LINK_EXPIRED = "ACTIVATION_LINK_EXPIRED";
export const RESEND_ACTIVATION_SUCCESS = "RESEND_ACTIVATION_SUCCESS";
export const RESEND_ACTIVATION_FAILED = "RESEND_ACTIVATION_FAILED";
export const VALIDATE_PASSWORD = "VALIDATE_PASSWORD";
export const CLOSE_RESEND_ACTIVATION_TOAST = "CLOSE_RESEND_ACTIVATION_TOAST";
export const LOADING_DIRECTORY_SEARCH = "LOADING_DIRECTORY_SEARCH";
export const LOADING_DIRECTORY_LIST = "LOADING_DIRECTORY_LIST";
export const LOAD_DIRECTORY_LIST_SUCCESS = "LOAD_DIRECTORY_LIST_SUCCESS";
export const CONTACT_SEARCH_RESULTS_SUCCESS = "CONTACT_SEARCH_RESULTS_SUCCESS";
export const CONTACT_SEARCH_RESULTS_FAILED = "CONTACT_SEARCH_RESULTS_FAILED";
export const GET_CONTACT_RATING_LIST = "GET_CONTACT_RATING_LIST";
export const GET_JOB_DESCRIPTION_LIST = "GET_JOB_DESCRIPTION_LIST";
export const GET_CONTACT_TYPE_LIST = "GET_CONTACT_TYPE_LIST";
export const GET_CONTACT_INFO_TYPE_LIST = "GET_CONTACT_INFO_TYPE_LIST";
export const GET_PREFIX_TYPE_LIST = "GET_PREFIX_TYPE_LIST";
export const GET_HOLIDAY_GREETING_TYPE_LIST = "GET_HOLIDAY_GREETING_TYPE_LIST";
export const GET_GAVE_GIFT_TYPE_LIST = "GET_GAVE_GIFT_TYPE_LIST";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const START_SUBMIT_CONTACT = "START_SUBMIT_CONTACT";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAILED = "CREATE_CONTACT_FAILED";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAILED = "UPDATE_CONTACT_FAILED";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const CLEAR_CONTACT = "CLEAR_CONTACT";
export const START_DELETE_CONTACT = "START_DELETE_CONTACT";
export const START_GET_CONTACT = "START_GET_CONTACT";
export const GET_CONTACT_BY_ID = "GET_CONTACT_BY_ID";
export const RESET_DIRECTORY_LINK_CLICKED = "RESET_DIRECTORY_LINK_CLICKED";
export const SHOW_DIRECTORY_ERROR_TOAST = "SHOW_DIRECTORY_ERROR_TOAST";
export const CLOSE_DIRECTORY_TOAST = "CLOSE_DIRECTORY_TOAST";
export const CLICK_DIRECTORY_LINK = "CLICK_DIRECTORY_LINK";
export const LOAD_MISSING_QUOTES_SUCCESS = "LOAD_MISSING_QUOTES_SUCCESS";
export const PASS_MQ_DEAL_SUCCESS = "PASS_MQ_DEAL_SUCCESS";
export const PASS_MQ_DEAL_FAILED = "PASS_MQ_DEAL_FAILED";
export const UPDATE_MQ_NOTE = "UPDATE_MQ_NOTE";
export const UPDATE_MQ_DEAL_NOTE = "UPDATE_MQ_DEAL_NOTE";
export const CLOSE_UPDATE_NOTE_SUCCESS_MQ_TOAST =
  "CLOSE_UPDATE_NOTE_SUCCESS_MQ_TOAST";
export const ERROR_UPDATE_MQ_NOTE = "ERROR_UPDATE_MQ_NOTE";
export const SUCCESS_BANK_UPDATE_MQ_QTS_STAR =
  "SUCCESS_BANK_UPDATE_MQ_QTS_STAR";
export const SUCCESS_DEAL_UPDATE_MQ_QTS_STAR =
  "SUCCESS_DEAL_UPDATE_MQ_QTS_STAR";
export const ERROR_UPDATE_MQ_QTS_STAR = "ERROR_UPDATE_MQ_QTS_STAR";
export const CLOSE_UPDATE_MQ_QTS_STAR = "CLOSE_UPDATE_MQ_QTS_STAR";
export const DEAL_QUOTE_DETAIL_SUCCESS = "DEAL_QUOTE_DETAIL_SUCCESS";
export const DEAL_QUOTE_UPDATE = "DEAL_QUOTE_UPDATE";
export const INCREMENT_QUOTE_FORM_AMOUNT = "INCREMENT_QUOTE_FORM_AMOUNT";
export const DECREMENT_QUOTE_FORM_AMOUNT = "DECREMENT_QUOTE_FORM_AMOUNT";
export const REMOVE_QUOTE_FORM_AMOUNT = "REMOVE_QUOTE_FORM_AMOUNT";
export const CLOSE_MISSING_QUOTE_TOAST = "CLOSE_MISSING_QUOTE_TOAST";
export const LOAD_WAITING_TERM_SHEETS_SUCCESS =
  "LOAD_WAITING_TERM_SHEETS_SUCCESS";
export const LOAD_DEAL_RATE_SPREAD = "LOAD_DEAL_RATE_SPREAD";
export const NO_QUOTES = "NO_QUOTES";
export const GET_GOOGLE_DOC_FAILED = "GET_GOOGLE_DOC_FAILED";
export const LOAD_IN_PROCESS_PIPELINE = "LOAD_IN_PROCESS_PIPELINE";
export const NO_IN_PROCESS_PIPELINE = "NO_IN_PROCESS_PIPELINE";
export const LOAD_ACTIVE_DEALS_SUCCESS = "LOAD_ACTIVE_DEALS_SUCCESS";
export const NO_ACTIVE_DEALS = "NO_ACTIVE_DEALS";
export const LOAD_REMAINING_DEALS_SUCCESS = "LOAD_REMAINING_DEALS_SUCCESS";
export const NO_REMAINING_DEALS = "NO_REMAINING_DEALS";
export const LOADING_REONOMY_PROPERTIES = "LOADING_REONOMY_PROPERTIES";
export const NO_REONOMY_PROPERTIES = "NO_REONOMY_PROPERTIES";
export const LOAD_REONOMY_PROPERTIES = "LOAD_REONOMY_PROPERTIES";
export const UPDATE_PROPERTY_NOTE_FAILED = "UPDATE_PROPERTY_NOTE_FAILED";
export const UPDATE_PROPERTY_NOTE_SUCCESS = "UPDATE_PROPERTY_NOTE_SUCCESS";
export const LOAD_VIOLATIONS = "LOAD_VIOLATIONS";
export const LOADING_VIOLATIONS = "LOADING_VIOLATIONS";
export const LOADING_STATUS_SUMMARY = "LOADING_STATUS_SUMMARY";
export const LOAD_STATUS_SUMMARY = "LOAD_STATUS_SUMMARY";
export const NO_VIOLATIONS = "NO_VIOLATIONS";
export const NO_STATUS_SUMMARY = "NO_STATUS_SUMMARY";
export const LOAD_BANK_QUOTES = "LOAD_BANK_QUOTES";
export const LOADING_BANK_QUOTES = "LOADING_BANK_QUOTES";
export const NO_BANK_QUOTES = "NO_BANK_QUOTES";
export const NO_MY_LOAN_DATA = "NO_MY_LOAN_DATA";
export const LOAD_MY_LOAN_DATA = "LOAD_MY_LOAN_DATA";
export const LOADING_MY_LOAN_DATA = "LOADING_MY_LOAN_DATA";
export const UPDATE_LOAN_SEARCH_VALUE = "UPDATE_LOAN_SEARCH_VALUE";
export const LOADING_ALL_LOAN_DATA = "LOADING_ALL_LOAN_DATA";
export const NO_ALL_LOAN_DATA = "NO_ALL_LOAN_DATA";
export const LOAD_ALL_LOAN_DATA = "LOAD_ALL_LOAN_DATA";
export const LOADING_PROPERTY_DETAILS = "LOADING_PROPERTY_DETAILS";
export const LOAD_PROPERTY_DETAILS = "LOAD_PROPERTY_DETAILS";
export const CLOSE_TOAST = "CLOSE_TOAST";

export const ERROR_IP_DD_QUOTE = "ERROR_IP_DD_QUOTE";
export const PASS_IP_DD_QUOTE_SUCCESS = "PASS_IP_DD_QUOTE_SUCCESS";
export const SNOOZE_IP_DD_QUOTE = "SNOOZE_IP_DD_QUOTE";
export const IRRELEVANT_IP_DD_QUOTE = "IRRELEVANT_IP_DD_QUOTE";
export const CLOSE_IP_DD_QUOTE = "CLOSE_IP_DD_QUOTE";

export const LOAD_DEAL_CONTACTS = "LOAD_DEAL_CONTACTS";
export const LOADING_DEAL_CONTACTS = "LOADING_DEAL_CONTACTS";
export const NO_DEAL_CONTACTS = "NO_DEAL_CONTACTS";
export const LOADING_FULL_CONTACT = "LOADING_FULL_CONTACT";
export const LOAD_FULL_CONTACT = "LOAD_FULL_CONTACT";
export const NO_FULL_CONTACT = "NO_FULL_CONTACT";
export const LOADING_DEAL_DOCUMENTS = "LOADING_DEAL_DOCUMENTS";
export const LOAD_DEAL_DOCUMENTS = "LOAD_DEAL_DOCUMENTS";
export const NO_DEAL_DOCUMENTS = "NO_DEAL_DOCUMENTS";
export const LOAD_WRITE_UP = "LOAD_WRITE_UP";
export const LOADING_WRITE_UP = "LOADING_WRITE_UP";
export const NO_WRITE_UP = "NO_WRITE_UP";
export const LOAD_DEAL_NOTES = "LOAD_DEAL_NOTES";
export const LOADING_DEAL_NOTES = "LOADING_DEAL_NOTES";
export const NO_DEAL_NOTES = "NO_DEAL_NOTES";
export const CLEAR_NOTE = "CLEAR_NOTE";
export const LOAD_NOTE_BY_ID = "LOAD_NOTE_BY_ID";
export const LOADING_NOTE_BY_ID = "LOADING_NOTE_BY_ID";
export const NO_NOTE_BY_ID = "NO_NOTE_BY_ID";
export const LOADING_DEAL_BY_ID = "LOADING_DEAL_BY_ID";
export const LOAD_DEAL_BY_ID = "LOAD_DEAL_BY_ID";
export const NO_DEAL_BY_ID = "NO_DEAL_BY_ID";
export const GET_BROKERS_BY_DEAL = "GET_BROKERS_BY_DEAL";
export const ASSIGN_NOTE = "ASSIGN_NOTE";
export const LOADING_SERVICE_PROVIDER_SUCCESS =
  "LOADING_SERVICE_PROVIDER_SUCCESS";
export const LOAD_SERVICE_PROVIDER_SUCCESS = "LOAD_SERVICE_PROVIDER_SUCCESS";
export const NO_IP_DEALS = "NO_IP_DEALS";
export const PW_STAR_CHANGE_COMPLETED = "PW_STAR_CHANGE_COMPLETED";
export const FPW_STAR_CHANGE_COMPLETED = "FPW_STAR_CHANGE_COMPLETED";
export const IP_STAR_CHANGE_COMPLETED = "IP_STAR_CHANGE_COMPLETED";
export const SEARCH_STAR_CHANGE_COMPLETED = "SEARCH_STAR_CHANGE_COMPLETED";
export const LOADING_UPDATE_STAR = "LOADING_UPDATE_STAR";
export const UPDATE_STAR_COMPLETED = "UPDATE_STAR_COMPLETED";
export const UPDATE_PW_RATING_SUCCESS = "UPDATE_PW_RATING_SUCCESS";
export const UPDATE_FPW_RATING_SUCCESS = "UPDATE_FPW_RATING_SUCCESS";
export const UPDATE_SEARCH_RATING_SUCCESS = "UPDATE_SEARCH_RATING_SUCCESS";
export const LOAD_IP_DEALS_SUCCESS = "LOAD_IP_DEALS_SUCCESS";
export const LOADING_IP_DEALS = "LOADING_IP_DEALS";
export const UPDATE_SEARCH_IP_DEALS = "UPDATE_SEARCH_IP_DEALS";
export const NO_PW_DEALS = "NO_PW_DEALS";
export const NO_FPW_DEALS = "NO_FPW_DEALS";
export const LOAD_PW_DEALS_SUCCESS = "LOAD_PW_DEALS_SUCCESS";
export const LOAD_FPW_DEALS_SUCCESS = "LOAD_FPW_DEALS_SUCCESS";
export const LOADING_PW_DEALS = "LOADING_PW_DEALS";
export const LOADING_FPW_DEALS = "LOADING_FPW_DEALS";
export const UPDATE_SEARCH_PW_DEALS = "UPDATE_SEARCH_PW_DEALS";
export const UPDATE_SEARCH_FPW_DEALS = "UPDATE_SEARCH_FPW_DEALS";
export const UPDATE_SEARCH_DEAL_IP_DATE = "UPDATE_SEARCH_DEAL_IP_DATE";
export const UPDATE_PW_DEAL_IP_DATE = "UPDATE_PW_DEAL_IP_DATE";
export const UPDATE_FPW_DEAL_IP_DATE = "UPDATE_FPW_DEAL_IP_DATE";
export const CLOSE_PW_DEAL_TOAST = "CLOSE_PW_DEAL_TOAST";
export const CLOSE_FPW_DEAL_TOAST = "CLOSE_FPW_DEAL_TOAST";
export const LOADING_UW_DEALS = "LOADING_UW_DEALS";
export const LOAD_UW_DEALS = "LOAD_UW_DEALS";
export const UPDATE_SEARCH_UW_DEALS = "UPDATE_SEARCH_UW_DEALS";
export const UW_STAR_CHANGE_COMPLETED = "UW_STAR_CHANGE_COMPLETED";
export const UPDATE_UW_DEAL_IP_DATE = "UPDATE_UW_DEAL_IP_DATE";
export const UPDATE_UW_RATING_SUCCESS = "UPDATE_UW_RATING_SUCCESS";
export const DEAL_REVIEWED_COMPLETED = "DEAL_REVIEWED_COMPLETED";
export const ADD_UPDATE_DEAL_NOTE_FAILED = "ADD_UPDATE_DEAL_NOTE_FAILED";
export const ADD_UPDATE_DEAL_NOTE_SUCCESS = "ADD_UPDATE_DEAL_NOTE_SUCCESS";
export const CLOSE_DEAL_NOTE_FAILED_TOAST = "CLOSE_DEAL_NOTE_FAILED_TOAST";
export const CLOSE_DEAL_NOTE_SUCCESS_TOAST = "CLOSE_DEAL_NOTE_SUCCESS_TOAST";
export const SEARCHING_DEALS = "SEARCHING_DEALS";
export const LOAD_SEARCH_DEALS = "LOAD_SEARCH_DEALS";
export const CLOSE_SEARCH_DEALS_TOAST = "CLOSE_SEARCH_DEALS_TOAST";
export const LOADING_FUND_QUOTES = "LOADING_FUND_QUOTES";
export const GET_FUND_QUOTES = "GET_FUND_QUOTES";
export const GET_FUND_QUOTES_FAILED = "GET_FUND_QUOTES_FAILED";
export const GET_EQUITY_QUOTE_STATUS_LIST = "GET_EQUITY_QUOTE_STATUS_LIST";
export const GET_WATERFALL_TYPE_LIST = "GET_WATERFALL_TYPE_LIST";
export const SHOW_DEAL_DETAILS_TOAST = "SHOW_DEAL_DETAILS_TOAST";
export const CLOSE_DEAL_DETAILS_TOAST = "CLOSE_DEAL_DETAILS_TOAST";
export const GET_RATES = "GET_RATES";
export const GET_RATES_PAGE = "GET_RATES_PAGE";
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_FIRST_TWO = "GET_NEWS_FIRST_TWO";
export const LOADING_NEWS = "LOADING_NEWS";
export const SET_ALL_NEWS_FILTER_NONE = "SET_ALL_NEWS_FILTER_NONE";
export const GET_INITIAL_NEWS_FILTER = "GET_INITIAL_NEWS_FILTER";
export const UPDATE_NEWS_FILTER = "UPDATE_NEWS_FILTER";
export const RESET_NEWS_FILTER = "RESET_NEWS_FILTER";
export const UPDATE_NAV_TITLE = "UPDATE_NAV_TITLE";
export const SET_PAGE_AND_SCROLL_P0S = "SET_PAGE_AND_SCROLL_P0S";
export const RESET_SCROLL_POS = "RESET_SCROLL_POS";
export const POP_PAGE_AND_SCROLL_P0S = "POP_PAGE_AND_SCROLL_P0S";
export const NAV_BAR_CLICKED = "NAV_BAR_CLICKED";
export const MAP_LOADED = "MAP_LOADED";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const TOGGLE_DIRECTORY_HIDE = "TOGGLE_DIRECTORY_HIDE";
export const LOADING_PROFILE = "LOADING_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLOSE_PROFILE_TOAST = "CLOSE_PROFILE_TOAST";
export const LOAD_QTS_BANK_QUOTES = "LOAD_QTS_BANK_QUOTES";
export const LOAD_QTS_BANK_DEALS_FAILED = "LOAD_QTS_BANK_DEALS_FAILED";
export const LOAD_PROPERTY_TYPE_LIST = "LOAD_PROPERTY_TYPE_LIST";
export const LOAD_QTS_BROKER_DEALS = "LOAD_QTS_BROKER_DEALS";
export const LOAD_QTS_BROKER_DEALS_FAILED = "LOAD_QTS_BROKER_DEALS_FAILED";
export const LOADING_DEALS = "LOADING_DEALS";
export const LOADING_QUOTES = "LOADING_QUOTES";
export const NO_QUOTE_DATA = "NO_QUOTE_DATA";
export const NO_DEAL_DATA = "NO_DEAL_DATA";
export const LOAD_QTS_QUOTES = "LOAD_QTS_QUOTES";
export const LOAD_QTS_QUOTES_FAILED = "LOAD_QTS_QUOTES_FAILED";
export const SAVE_FORM = "SAVE_FORM";
export const LOADING_QTS_MAP = "LOADING_QTS_MAP";
export const LOAD_QTS_MAP_DATA = "LOAD_QTS_MAP_DATA";
export const NO_QTS_MAP_DATA = "NO_QTS_MAP_DATA";
export const NO_DATA = "NO_DATA";
export const UPDATE_GENERAL_STARRED_SUCCESS = "UPDATE_GENERAL_STARRED_SUCCESS";
export const LOADING_CONTACT_DETAILS = "LOADING_CONTACT_DETAILS";
export const LOAD_CONTACT_DETAILS = "LOAD_CONTACT_DETAILS";
export const NO_CONTACT_DETAILS = "NO_CONTACT_DETAILS";
export const ADD_CONTACT_DETAILS_SUCCESS = "ADD_CONTACT_DETAILS_SUCCESS";
export const ADD_CONTACT_DETAILS_FAILED = "ADD_CONTACT_DETAILS_FAILED";
export const UPDATE_NOTE_SUCCESS_TOAST = "UPDATE_NOTE_SUCCESS_TOAST";
export const UPDATE_NOTE_SUCCESS_DATE_AND_TOAST =
  "UPDATE_NOTE_SUCCESS_DATE_AND_TOAST";
export const UPDATE_NOTE_FAILED_TOAST = "UPDATE_NOTE_FAILED_TOAST";
export const SET_CONTACT_SCROLL_P0S = "SET_CONTACT_SCROLL_P0S";
export const LOADING_PROPERTIES = "LOADING_PROPERTIES";
export const NO_PROPERTIES = "NO_PROPERTIES";
export const LOAD_PROPERTIES_SUCCESS = "LOAD_PROPERTIES_SUCCESS";
export const UPDATE_PROPERTY_SEARCH = "UPDATE_PROPERTY_SEARCH";
export const REMOVE_PROPERTY_FAILED = "REMOVE_PROPERTY_FAILED";
export const REMOVE_PROPERTY_SUCCESS = "REMOVE_PROPERTY_SUCCESS";

export const LOAD_CLIENT_STATUS = "LOAD_CLIENT_STATUS";
export const SUBMIT_CLIENT_STATUS_FAILED = "SUBMIT_CLIENT_STATUS_FAILED";
export const SUBMIT_CLIENT_STATUS_SUCCESS = "SUBMIT_CLIENT_STATUS_SUCCESS";
export const LOAD_LP_SERVICE_STATUS = "LOAD_LP_SERVICE_STATUS";
export const SUBMIT_LP_STATUS_FAILED = "SUBMIT_LP_STATUS_FAILED";
export const SUBMIT_LP_STATUS_SUCCESS = "SUBMIT_LP_STATUS_SUCCESS";
export const CLOSE_LP_CLIENT_STATUS_TOAST = "CLOSE_LP_CLIENT_STATUS_TOAST";
export const CHANGE_CLIENT_RATING_SUCCESS = "CHANGE_CLIENT_RATING_SUCCESS";
export const CHANGE_CLIENT_RATING_FAILED = "CHANGE_CLIENT_RATING_FAILED";
export const LOADING_BROKER_QUOTES = "LOADING_BROKER_QUOTES";
export const NO_BROKER_QUOTES = "NO_BROKER_QUOTES";
export const LOAD_BROKER_MISSING_QUOTES_SUCCESS =
  "LOAD_BROKER_MISSING_QUOTES_SUCCESS";
export const GET_UNCONFIRMED_DEALS_OVERDUE = "GET_UNCONFIRMED_DEALS_OVERDUE";
export const GET_UNCONFIRMED_DEALS_COUNT = "GET_UNCONFIRMED_DEALS_COUNT";
export const GET_UNCONFIRMED_DEALS = "GET_UNCONFIRMED_DEALS";
export const GET_UNCONF_DEALS_NO_MONEY = "GET_UNCONF_DEALS_NO_MONEY";
export const COMMENT_ON_COMMISSION = "COMMENT_ON_COMMISSION";
export const CONFIRM_COMMISSION = "CONFIRM_COMMISSION";
export const CONFIRM_COMMISSION_NO_MONEY = "CONFIRM_COMMISSION_NO_MONEY";

//Missing Quotes Broker

export const NO_ADMIN_VIEW_DATA = "NO_ADMIN_VIEW_DATA";
export const GET_ADMIN_VIEW_DATA = "GET_ADMIN_VIEW_DATA";
export const REMOVE_ADMIN_VIEW_DATA = "REMOVE_ADMIN_VIEW_DATA";
export const LOADING_ADMIN_VIEW_DATA = "LOADING_ADMIN_VIEW_DATA";
export const SNOOZE_MQ_BROKER_DEAL_SUCCESS = "SNOOZE_MQ_BROKER_DEAL_SUCCESS";
export const IRRELEVANT_MQ_BROKER_DEAL_SUCCESS =
  "IRRELEVANT_MQ_BROKER_DEAL_SUCCESS";
export const PASS_MQ_BROKER_DEAL_SUCCESS = "PASS_MQ_BROKER_DEAL_SUCCESS";
export const CLOSE_MQ_PASS_DEAL_SUCCESS = "CLOSE_MQ_PASS_DEAL_SUCCESS";
export const CLOSE_MQ_SNOOZE_DEAL_SUCCESS = "CLOSE_MQ_SNOOZE_DEAL_SUCCESS";
export const CLOSE_MQ_IRRELEVANT_DEAL_SUCCESS =
  "CLOSE_MQ_IRRELEVANT_DEAL_SUCCESS";
export const ERROR_UPDATING_MQ_BROKER = "ERROR_UPDATING_MQ_BROKER";
export const CLOSE_ERROR_UPDATING_MQ_BROKER = "CLOSE_ERROR_UPDATING_MQ_BROKER";

export const LOAD_SEARCH_MQ_BROKER_DATA = "LOAD_SEARCH_MQ_BROKER_DATA";
export const NO_MQ_BROKER_SEARCH_RESULTS = "NO_MQ_BROKER_SEARCH_RESULTS";
export const LOADING_SEARCH_BROKER_QUOTES = "LOADING_SEARCH_BROKER_QUOTES";

//Lending criteria

export const LOADING_PARAM_LIST = "LOADING_PARAM_LIST";
export const NO_PARAM_LIST = "NO_PARAM_LIST";
export const LOAD_PARAM_LIST = "LOAD_PARAM_LIST";
export const LOAD_PARAM_LIST_FAILED = "LOAD_PARAM_LIST_FAILED";
export const CLOSE_ASK_QTS_TOAST = "CLOSE_ASK_QTS_TOAST";
export const LOADING_PARAM_BY_ID = "LOADING_PARAM_BY_ID";
export const NO_PARAM_BY_ID = "NO_PARAM_BY_ID";
export const LOAD_PARAM_BY_ID = "LOAD_PARAM_BY_ID";
export const LOAD_LENDING_TYPES = "LOAD_LENDING_TYPES";
export const LOAD_MAX_TERM_TYPES = "LOAD_MAX_TERM_TYPES";
export const LOAD_MIN_DSCR_TYPES = "LOAD_MIN_DSCR_TYPES";
export const LOAD_MAX_INTEREST_ONLY_TYPES = "LOAD_MAX_INTEREST_ONLY_TYPES";
export const LOAD_PREPAYMENT_PENALTY_TYPES = "LOAD_PREPAYMENT_PENALTY_TYPES";
export const LOAD_MAX_INITIAL_TERM_TYPES = "LOAD_MAX_INITIAL_TERM_TYPES";
export const LOAD_MAX_PERM_AFTER_CONSTRUCTION_TYPES = "LOAD_MAX_PERM_AFTER_CONSTRUCTION_TYPES";
export const LOAD_MAX_AMORTIZATION_TYPES = "LOAD_MAX_AMORTIZATION_TYPES";
export const LOAD_SIMPLE_RATE_SHEET_TYPES = "LOAD_SIMPLE_RATE_SHEET_TYPES";
export const LOAD_PROP_TYPES = "LOAD_PROP_TYPES";
export const LOAD_STATE_LIST = "LOAD_STATE_LIST";
export const LOAD_RECOURSE_TYPES = "LOAD_RECOURSE_TYPES";
export const ADD_LENDING_CRITERIA_SUCCESS = "ADD_LENDING_CRITERIA_SUCCESS";
export const UPDATE_LENDING_CRITERIA_SUCCESS =
  "UPDATE_LENDING_CRITERIA_SUCCESS";
export const CLOSE_LENDING_CRITERIA_SUCCESS = "CLOSE_LENDING_CRITERIA_SUCCESS";
export const GET_CITY_STATES_LIST = "GET_CITY_STATES_LIST";

//Comps actions

export const LOAD_COMPS = "LOAD_COMPS";
export const CLICK_COMPS_LINK = "CLICK_COMPS_LINK";
export const UPDATE_COMPS_SEARCH_BAR = "UPDATE_COMPS_SEARCH_BAR";
export const LOAD_SEARCH_COMPS = "LOAD_SEARCH_COMPS";
export const COMPS_ERROR_MESSAGE = "COMPS_ERROR_MESSAGE";
export const CLOSE_ERROR_TOAST = "CLOSE_ERROR_TOAST";
export const CLOSE_LOADING_TOAST = "CLOSE_LOADING_TOAST";
export const CLOSE_COMPS_MODAL = "CLOSE_COMPS_MODAL";
export const DEFAULT_SEARCH_COMP_LOAD = "DEFAULT_SEARCH_COMP_LOAD";
export const LOADING_COMPS = "LOADING_COMPS";
export const COMPS_LOADED = "COMPS_LOADED";
export const RESET_COMPS_STATE = "RESET_COMPS_STATE";
export const LOAD_PROPERTY_TYPES = "LOAD_PROPERTY_TYPES";
export const UPDATE_ERROR_TOAST = "UPDATE_ERROR_TOAST";
export const SET_COMPS_PAGE = "SET_COMPS_PAGE";
export const REQUEST_QUOTE = "REQUEST_QUOTE";
export const CLOSE_REQUEST_QUOTE_TOAST = "CLOSE_REQUEST_QUOTE_TOAST";
export const GET_TOP_EU_BROKERS = "GET_TOP_EU_BROKERS";
export const GET_TOP_BROKERS_IN_AREA = "GET_TOP_BROKERS_IN_AREA";
export const GET_QTS_BROKER = "GET_QTS_BROKER";

//Contact actions:

export const LOADING_DEFAULT_CONTACTS = "LOADING_DEFAULT_CONTACTS";
export const LOAD_DEFAULT_CONTACTS = "LOAD_DEFAULT_CONTACTS";
export const LOADING_PW_IP_CONTACTS = "LOADING_PW_IP_CONTACTS";
export const LOADING_FPW_IP_CONTACTS = "LOADING_FPW_IP_CONTACTS";
export const NO_PW_IP_CONTACTS = "NO_PW_IP_CONTACTS";
export const NO_FPW_IP_CONTACTS = "NO_FPW_IP_CONTACTS";
export const LOAD_PW_IP_CONTACTS_SUCCESS = "LOAD_PW_IP_CONTACTS_SUCCESS";
export const LOAD_FPW_IP_CONTACTS_SUCCESS = "LOAD_FPW_IP_CONTACTS_SUCCESS";
export const LOADING_STARRED_CONTACTS = "LOADING_STARRED_CONTACTS";
export const NO_STARRED_CONTACTS = "NO_STARRED_CONTACTS";
export const LOAD_STARRED_CONTACTS_SUCCESS = "LOAD_STARRED_CONTACTS_SUCCESS";
export const LOADING_CLOSING_CLIENTS_CONTACTS =
  "LOADING_CLOSING_CLIENTS_CONTACTS";
export const NO_CLOSING_CLIENTS_CONTACTS = "NO_CLOSING_CLIENTS_CONTACTS";
export const LOAD_CLOSING_CLIENTS_CONTACTS_SUCCESS =
  "LOAD_CLOSING_CLIENTS_CONTACTS_SUCCESS";
export const LOADING_PREDICTIVE_CONTACTS = "LOADING_PREDICTIVE_CONTACTS";
export const LOAD_PREDICTIVE_CONTACTS_SUCCESS =
  "LOAD_PREDICTIVE_CONTACTS_SUCCESS";
export const NO_PREDICTIVE_CONTACTS = "NO_PREDICTIVE_CONTACTS";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const LOADING_PREDICTIVE_CONTACTS_SEARCH =
  "LOADING_PREDICTIVE_CONTACTS_SEARCH";
export const LOAD_PREDICTIVE_CONTACTS_SEARCH =
  "LOAD_PREDICTIVE_CONTACTS_SEARCH";
export const CONTACTS_SEARCH_FAIL = "CONTACTS_SEARCH_FAIL";
export const NO_PREDICTIVE_CONTACTS_SEARCH = "NO_PREDICTIVE_CONTACTS_SEARCH";
export const UPDATE_DEFAULT_CONTACT_COMPLETED =
  "UPDATE_DEFAULT_CONTACT_COMPLETED";
export const UPDATE_PW_IP_CONTACT_COMPLETED = "UPDATE_PW_IP_CONTACT_COMPLETED";
export const UPDATE_FPW_IP_CONTACT_COMPLETED = "UPDATE_FPW_IP_CONTACT_COMPLETED";
export const UPDATE_STARRED_CONTACT_COMPLETED =
  "UPDATE_STARRED_CONTACT_COMPLETED";
export const UPDATE_CLOSING_CLIENT_CONTACT_COMPLETED =
  "UPDATE_CLOSING_CLIENT_CONTACT_COMPLETED";
export const UPDATE_RESULT_CONTACT_COMPLETED =
  "UPDATE_RESULT_CONTACT_COMPLETED";
export const CREATE_CLIENT_LOGIN_FAILED = "CREATE_CLIENT_LOGIN_FAILED";
export const SEND_WELCOME_EMAIL = "SEND_WELCOME_EMAIL";
export const UPDATE_DATE_SUCCESS = "UPDATE_DATE_SUCCESS";
export const UPDATE_DEFAULT_CONTACT_STARRED_SUCCESS =
  "UPDATE_DEFAULT_CONTACT_STARRED_SUCCESS";
export const UPDATE_STARRED_CONTACT_STARRED_SUCCESS =
  "UPDATE_STARRED_CONTACT_STARRED_SUCCESS";
export const UPDATE_PWIP_CONTACT_STARRED_SUCCESS =
  "UPDATE_PWIP_CONTACT_STARRED_SUCCESS";
export const UPDATE_CLOSING_CONTACT_STARRED_SUCCESS =
  "UPDATE_CLOSING_CONTACT_STARRED_SUCCESS";
export const UPDATE_RESULT_CONTACT_STARRED_SUCCESS =
  "UPDATE_RESULT_CONTACT_STARRED_SUCCESS";
export const CONTACT_GIVEBACK_CHECK_NOTE = "CONTACT_GIVEBACK_CHECK_NOTE";
export const GET_GIVEBACK_REASON_CODES = "GET_GIVEBACK_REASON_CODES";
export const CONTACT_GIVEBACK_SUCCESS = "CONTACT_GIVEBACK_SUCCESS";
export const CONTACT_GIVEBACK_FAILED = "CONTACT_GIVEBACK_FAILED";
export const NO_VIOLATIONS_FEEDBACK_TOAST = "NO_VIOLATIONS_FEEDBACK_TOAST";
export const CLOSE_VIOLATIONS_FEEDBACK_TOAST =
  "CLOSE_VIOLATIONS_FEEDBACK_TOAST";

export const CONTACT_PREVIOUS_PAGE = "CONTACT_PREVIOUS_PAGE";
export const NO_CONTACT_PREVIOUS_PAGE = "NO_CONTACT_PREVIOUS_PAGE";
export const CONTACT_NEXT_PAGE = "CONTACT_NEXT_PAGE";
export const NO_CONTACT_NEXT_PAGE = "NO_CONTACT_NEXT_PAGE";
export const LOADING_CONTACT_PAGE_INFO = "LOADING_CONTACT_PAGE_INFO";

//Calculator actions:

export const UPDATE_DEBT_YIELD = "UPDATE_DEBT_YIELD";
export const UPDATE_MAX_LOAN = "UPDATE_MAX_LOAN";
export const EXPORT_MAX_LOAN = "EXPORT_MAX_LOAN";
export const UPDATE_INTEREST_ONLY = "UPDATE_INTEREST_ONLY";
export const UPDATE_SIMPLE_UNDERWRITING = "UPDATE_SIMPLE_UNDERWRITING";
export const EXPORT_SIMPLE_UNDERWRITING = "EXPORT_SIMPLE_UNDERWRITING";
export const UPDATE_CASH_ON_CASH = "UPDATE_CASH_ON_CASH";
export const UPDATE_BASIC_CRE = "UPDATE_BASIC_CRE";
export const UPDATE_PI_AND_DSCR = "UPDATE_PI_AND_DSCR";
export const UPDATE_CAP_RATE = "UPDATE_CAP_RATE";
export const UPDATE_CENTRAL_STATE = "UPDATE_CENTRAL_STATE";
export const UPDATE_YIELD_MAINTENANCE = "UPDATE_YIELD_MAINTENANCE";
export const START_YIELD_MAINTENANCE_LOADING =
  "START_YIELD_MAINTENANCE_LOADING";
export const STOP_YIELD_MAINTENANCE_LOADING = "STOP_YIELD_MAINTENANCE_LOADING";
export const LOAD_TREASURY_RATES = "LOAD_TREASURY_RATES";
export const UPDATE_REVERSE_MORTGAGE = "UPDATE_REVERSE_MORTGAGE";
export const UPDATE_IRR = "UPDATE_IRR";
export const SHARED_CALCULATOR_SUCCESS = "SHARED_CALCULATOR_SUCCESS";
export const SHARED_CALCULATOR_ERROR = "SHARED_CALCULATOR_ERROR";
export const CLOSE_SHARED_CALCULATOR_TOAST = "CLOSE_SHARED_CALCULATOR_TOAST";
export const UPDATE_LOAN_SYNDICATION = "UPDATE_LOAN_SYNDICATION";

//Broker Reports

export const LOADING_REPORTS_LIST = "LOADING_REPORTS_LIST";
export const LOAD_REPORT_LIST_FAILED = "LOAD_REPORT_LIST_FAILED";
export const LOAD_REPORT_LIST_SUCCESS = "LOAD_REPORT_LIST_SUCCESS";
export const LOADING_REPORTS = "LOADING_REPORTS";
export const LOAD_REPORT_FAILED = "LOAD_REPORT_FAILED";
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";
export const NO_REPORT = "NO_REPORT";
export const GET_MEMBERS_LIST = "GET_MEMBERS_LIST";
export const GET_MEMBERS_LIST_FAILED = "GET_MEMBERS_LIST_FAILED";
export const GET_BANKS_LIST_SUCCESS = "GET_BANKS_LIST_SUCCESS";
export const GET_BANKS_LIST_FAILED = "GET_BANKS_LIST_FAILED";
export const SET_CURRENT_BANK = "SET_CURRENT_BANK";
export const SET_CURRENT_BANKER = "SET_CURRENT_BANKER";
export const SET_BANKS_BANKERS = "SET_BANKS_BANKERS";
export const GET_BANKS_BANKERS_SUCCESS = "GET_BANKS_BANKERS_SUCCESS";
export const GET_BANKS_BANKERS_FAILED = "GET_BANKS_BANKERS_FAILED";
export const CLOSE_REPORT_TOAST = "CLOSE_REPORT_TOAST";
export const LOADING_LOAN_CONTACT_DETAILS = "LOADING_LOAN_CONTACT_DETAILS";
export const LOAD_LOAN_CONTACT_DETAILS = "LOAD_LOAN_CONTACT_DETAILS";
export const NO_LOAN_CONTACT_DETAILS = "NO_LOAN_CONTACT_DETAILS";
export const PASS_LOAN_ERROR = "PASS_LOAN_ERROR";
export const PASS_LOAN_SUCCESS = "PASS_LOAN_SUCCESS";
export const QUOTE_LOAN_SUCCESS = "QUOTE_LOAN_SUCCESS";
export const CLOSE_LOAN_TOAST = "CLOSE_LOAN_TOAST";
export const GET_BANK_PRESIDENT_EMPLOYEES_SUCCESS =
  "GET_BANK_PRESIDENT_EMPLOYEES_SUCCESS";
export const GET_BANK_PRESIDENT_EMPLOYEES_FAILED =
  "GET_BANK_PRESIDENT_EMPLOYEES_FAILED";
export const SHOW_LOAN_NO_DATA_TOAST = "SHOW_LOAN_NO_DATA_TOAST";
export const CLOSE_LOAN_NO_DATA_TOAST = "CLOSE_LOAN_NO_DATA_TOAST";
export const ADD_BANKER_TO_ALL_LOAN_SUCCESS = "ADD_BANKER_TO_ALL_LOAN_SUCCESS";
export const ADD_BANKER_TO_ALL_LOAN_FAILED = "ADD_BANKER_TO_ALL_LOAN_FAILED";
export const REMOVE_BANKER_FROM_ALL_LOAN_SUCCESS =
  "REMOVE_BANKER_FROM_ALL_LOAN_SUCCESS";
export const REMOVE_BANKER_FROM_ALL_LOAN_FAILED =
  "REMOVE_BANKER_FROM_ALL_LOAN_FAILED";
export const REMOVE_BANKER_FROM_MY_LOAN_SUCCESS =
  "REMOVE_BANKER_FROM_MY_LOAN_SUCCESS";
export const REMOVE_BANKER_FROM_MY_LOAN_FAILED =
  "REMOVE_BANKER_FROM_MY_LOAN_FAILED";

export const UNSUBSCRIBE = "UNSUBSCRIBE";

//Investing Criteria
export const LOADING_INVESTING_CRITERIA = "LOADING_INVESTING_CRITERIA";
export const GET_INVESTING_CRITERIA_LIST = "GET_INVESTING_CRITERIA_LIST";
export const GET_INVESTING_CRITERIA = "GET_INVESTING_CRITERIA";
export const GET_FUND_TYPES = "GET_FUND_TYPES";
export const GET_INVESTING_CRITERIA_RETURN_TYPES =
  "GET_INVESTING_CRITERIA_RETURN_TYPES";
export const GET_INVESTMENT_TYPES = "GET_INVESTMENT_TYPES";
export const GET_CONSTRUCTION_TYPES = "GET_CONSTRUCTION_TYPES";
export const ADD_INVESTING_CRITERIA = "ADD_INVESTING_CRITERIA";
export const EDIT_INVESTING_CRITERIA = "EDIT_INVESTING_CRITERIA";
export const DELETE_INVESTING_CRITERIA = "DELETE_INVESTING_CRITERIA";
export const CLOSE_IC_TOAST = "CLOSE_IC_TOAST";

//Fund Portfolio
export const LOADING_FUND_PORTFOLIOS = "LOADING_FUND_PORTFOLIOS";
export const GET_FUND_PORTFOLIO_SUCCESS = "GET_FUND_PORTFOLIO_SUCCESS";
export const GET_FUND_PORTFOLIO_FAILED = "GET_FUND_PORTFOLIO_FAILED";
export const LOADING_EQUITY_QUOTE = "LOADING_EQUITY_QUOTE";
export const GET_EQUITY_QUOTE_SUCCESS = "GET_EQUITY_QUOTE_SUCCESS";
export const GET_EQUITY_QUOTE_FAILED = "GET_EQUITY_QUOTE_FAILED";
export const GET_QUOTE_NOTE_SUCCESS = "GET_QUOTE_NOTE_SUCCESS";
export const GET_QUOTE_NOTE_FAILED = "GET_QUOTE_NOTE_FAILED";
export const SAVE_EQUITY_QUOTE = "SAVE_EQUITY_QUOTE";
export const CREATE_QUOTE_NOTE_SUCCESS = "CREATE_QUOTE_NOTE_SUCCESS";
export const EDIT_QUOTE_NOTE_SUCCESS = "EDIT_QUOTE_NOTE_SUCCESS";
export const DELETE_QUOTE_NOTE_SUCCESS = "DELETE_QUOTE_NOTE_SUCCESS";
export const UPDATE_QUOTE_NOTE_FAILED = "UPDATE_QUOTE_NOTE_FAILED";
export const CLOSE_FUND_PORTFOLIO_TOAST = "CLOSE_FUND_PORTFOLIO_TOAST";

//Docs
export const LOADING_DOCS_BY_TYPE = "LOADING_DOCS_BY_TYPE";
export const GET_DOCS_BY_TYPE = "GET_DOCS_BY_TYPE";

//Accounting
export const LOADING_ACCOUNTING_REPORT = "LOADING_ACCOUNTING_REPORT";
export const GET_ACCOUNTING_REPORT_SUCCESS = "GET_ACCOUNTING_REPORT_SUCCESS";
export const GET_ACCOUNTING_REPORT_FAILED = "GET_ACCOUNTING_REPORT_FAILED";
export const CLOSE_ACCOUNTING_TOAST = "CLOSE_ACCOUNTING_TOAST";

//Deal Review
export const GET_DEAL_REVIEW_USERS = "GET_DEAL_REVIEW_USERS";
export const GET_PROPERTY_TYPES_LIST = "GET_PROPERTY_TYPES_LIST";
export const GET_DEAL_TYPES_LIST = "GET_DEAL_TYPES_LIST";
export const GET_LOAN_TYPES_LIST = "GET_LOAN_TYPES_LIST";
export const GET_STATES_LIST = "GET_STATES_LIST";
export const GET_DEAL_STATUSES_LIST = "GET_DEAL_STATUSES_LIST";
export const LOADING_DEAL_REVIEW = "LOADING_DEAL_REVIEW";
export const GET_DEAL_REVIEW_RESULTS = "GET_DEAL_REVIEW_RESULTS";
export const GET_DEAL_BANK_QUOTES = "GET_DEAL_BANK_QUOTES";
export const UPDATE_DEAL_REVIEW_SUCCESS = "UPDATE_DEAL_REVIEW_SUCCESS";
export const GET_UPDATED_DEAL_REVIEW = "GET_UPDATED_DEAL_REVIEW";
export const GET_UPDATED_DEAL_REVIEW_PROPERTY =
  "GET_UPDATED_DEAL_REVIEW_PROPERTY";
export const UPDATE_DEAL_REVIEW_FAILED = "UPDATE_DEAL_REVIEW_FAILED";
export const CLOSE_UPDATE_DEAL_REVIEW_TOAST = "CLOSE_UPDATE_DEAL_REVIEW_TOAST";
export const GET_FULL_CLIENT_INFO = "GET_FULL_CLIENT_INFO";
export const LOADING_FULL_CLIENT_INFO = "LOADING_FULL_CLIENT_INFO";

//eCalc
export const ECALC_TOGGLE_IS_DIRTY = "ECALC_TOGGLE_IS_DIRTY";
export const ECALC_ON_CHANGE = "ECALC_ON_CHANGE";
export const ECALC_ACTIVE_CALCULATOR = "ECALC_ACTIVE_CALCULATOR";
export const ECALC_ON_MULTI_CHANGE = "ECALC_ON_MULTI_CHANGE";
export const ECALC_ADD_RESULTS = "ECALC_ADD_RESULTS";
export const ECALC_REMOVE_RESULTS = "ECALC_REMOVE_RESULTS";
export const ECALC_ADD_ENTRY_METHOD = "ECALC_ADD_ENTRY_METHOD";
export const ECALC_ADD_ITEMIZATION_ENTRY_METHOD =
  "ECALC_ADD_ITEMIZATION_ENTRY_METHOD";
export const ECALC_DELETE_ITEMIZATION_ENTRY_METHOD =
  "ECALC_DELETE_ITEMIZATION_ENTRY_METHOD";
export const IRR_SCHEDULE_CHANGE = "IRR_SCHEDULE_CHANGE";
export const IRR_SCHEDULE_OPENED = "IRR_SCHEDULE_OPENED";
export const GET_ECALC_RATES = "GET_ECALC_RATES";
export const CLEAR_BLENDED_RATE_ITEMIZATION = "CLEAR_BLENDED_RATE_ITEMIZATION";
export const CLEAR_ECALC = "CLEAR_ECALC";
export const SET_OPEN_CALCULATORS = "SET_OPEN_CALCULATORS";
export const UPDATE_ITEMIZATION = "UPDATE_ITEMIZATION";
export const UPDATE_ITEMIZATION_PERCENT_OF = "UPDATE_ITEMIZATION_PERCENT_OF";
export const ECALC_DELETE_ITEMIZATION_PERCENT_OF =
  "ECALC_DELETE_ITEMIZATION_PERCENT_OF";
export const CLEAR_IRR_CAPITAL_EVENT = "CLEAR_IRR_CAPITAL_EVENT";
// no persist
export const ECALC_TOGGLE_IS_DIRTY_NP = "ECALC_TOGGLE_IS_DIRTY_NP";
export const ECALC_ON_CHANGE_NP = "ECALC_ON_CHANGE_NP";
export const ECALC_ACTIVE_CALCULATOR_NP = "ECALC_ACTIVE_CALCULATOR_NP";
export const ECALC_ON_MULTI_CHANGE_NP = "ECALC_ON_MULTI_CHANGE_NP";
export const ECALC_ADD_RESULTS_NP = "ECALC_ADD_RESULTS_NP";
export const ECALC_REMOVE_RESULTS_NP = "ECALC_REMOVE_RESULTS_NP";
export const ECALC_ADD_ENTRY_METHOD_NP = "ECALC_ADD_ENTRY_METHOD_NP";
export const ECALC_ADD_ITEMIZATION_ENTRY_METHOD_NP =
  "ECALC_ADD_ITEMIZATION_ENTRY_METHOD_NP";
export const ECALC_DELETE_ITEMIZATION_ENTRY_METHOD_NP =
  "ECALC_DELETE_ITEMIZATION_ENTRY_METHOD_NP";
export const IRR_SCHEDULE_CHANGE_NP = "IRR_SCHEDULE_CHANGE_NP";
export const IRR_SCHEDULE_OPENED_NP = "IRR_SCHEDULE_OPENED_NP";
export const GET_ECALC_RATES_NP = "GET_ECALC_RATES_NP";
export const CLEAR_BLENDED_RATE_ITEMIZATION_NP =
  "CLEAR_BLENDED_RATE_ITEMIZATION_NP";
export const CLEAR_ECALC_NP = "CLEAR_ECALC_NP";
export const SET_OPEN_CALCULATORS_NP = "SET_OPEN_CALCULATORS_NP";
export const UPDATE_ITEMIZATION_NP = "UPDATE_ITEMIZATION_NP";
export const UPDATE_ITEMIZATION_PERCENT_OF_NP =
  "UPDATE_ITEMIZATION_PERCENT_OF_NP";
export const ECALC_DELETE_ITEMIZATION_PERCENT_OF_NP =
  "ECALC_DELETE_ITEMIZATION_PERCENT_OF_NP";
export const CLEAR_IRR_CAPITAL_EVENT_NP = "CLEAR_IRR_CAPITAL_EVENT_NP";
export const ECALC_GET_SAVED_ECALC = "ECALC_GET_SAVED_ECALC";
export const ECALC_GET_SAVED_ECALC_NP = "ECALC_GET_SAVED_ECALC_NP";
export const SAVE_ECALC = "SAVE_ECALC";
export const SAVE_ECALC_NP = "SAVE_ECALC_NP";
export const START_SAVING_ECALC_NP = "START_SAVING_ECALC_NP";
export const START_SAVING_NEW_DEAL_NP = "START_SAVING_NEW_DEAL_NP";
export const SAVE_NEW_DEAL_FAILED_NP = "SAVE_NEW_DEAL_FAILED_NP";
export const SAVE_NEW_DEAL_SUCCESS_NP = "SAVE_NEW_DEAL_SUCCESS_NP";
export const CLEAR_NEW_DEAL_ERR = "CLEAR_NEW_DEAL_ERR";
export const SAVE_ECALC_FAILED_NP = "SAVE_ECALC_FAILED_NP";
export const SAVE_ECALC_SUCCESS_NP = "SAVE_ECALC_SUCCESS_NP";
export const LOADING_EXISTING_PROPERTIES_NP = "LOADING_EXISTING_PROPERTIES_NP";
export const LOAD_EXISTING_PROPERTIES_NP = "LOAD_EXISTING_PROPERTIES_NP";
export const SET_ECALC_LOADING_NP = "SET_ECALC_LOADING_NP";
export const CREATE_PUBLIC_ECALC_NP = "CREATE_PUBLIC_ECALC_NP";
export const DOWNLOAD_PDF_FAILED = "DOWNLOAD_PDF_FAILED";
export const DOWNLOAD_PDF_SUCCESS = "DOWNLOAD_PDF_SUCCESS";
export const CLEAR_TOOLBAR_DATA = "CLEAR_TOOLBAR_DATA";
export const GET_DEAL_IDS_FROM_CUSTOM_REPORT = "GET_DEAL_IDS_FROM_CUSTOM_REPORT";
export const DEAL_IDS_FROM_CUSTOM_REPORT = "DEAL_IDS_FROM_CUSTOM_REPORT";
export const NO_RATE_DATA = "NO_RATE_DATA";
export const LOAD_QTS_RATES = "LOAD_QTS_RATES";
export const LOAD_QTS_RATES_FAILED = "LOAD_QTS_RATES_FAILED";
export const LOADING_RATES = "LOADING_RATES";
export const LOADING_SPINNER = "LOADING_SPINNER";
export const SHOW_TOAST_ERROR = "SHOW_TOAST_ERROR";
