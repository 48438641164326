import React from "react";
import Row from "muicss/lib/react/row";
import { NavLink } from "react-router-dom";

class AccountingHeader extends React.Component {
  render() {
    return (
      <div className="docs-page">
        <Row className="sub-header">
          <div className="subheader">
            <ul>
              <li>
                <NavLink
                  to={`/accounting/summary`}
                  activeClassName="header-active-link"
                >
                  SUMMARY
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/accounting/earnings`}
                  activeClassName="header-active-link"
                >
                  EARNINGS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/accounting/payments`}
                  activeClassName="header-active-link"
                >
                  PAYMENTS
                </NavLink>
              </li>
            </ul>
          </div>
        </Row>
        <div className="docs-content-container">{this.props.children}</div>
      </div>
    );
  }
}

export default AccountingHeader;
