import "react-app-polyfill/ie11";
//import "./hotjar";
//TODO: remove once we confirm freshdesk is working as expected
// import './tawkChat';
import React, { Suspense, lazy, Fragment } from "react";
import { render } from "react-dom";
import { Redirect, Router, Route, Switch } from "react-router-dom";
import { connect, Provider } from "react-redux";
import querySerializer from "query-string";
import configureStore from "./store/configureStore";
import {
  Navigation,
  mixpanelRegisterPerson,
  mixpanelTrackLoc,
  registerGlobalData,
} from "./lib";

import * as serviceWorker from "./registerServiceWorker";
import serviceWorkerConfig from "./serviceWorkerConfig";
import { create as createJSS } from "jss";
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "./themes";

import App from "./components/App";
import AccountActivationPage from "./components/resetPassword/AccountActivationPage";
import AccountActivationPageNew from "./components/resetPassword/AccountActivationPageNew";
import AccountingHeader from "./components/accounting/AccountingHeader";
import ActivationSuccessful from "./components/home/ActivationSuccessful";
import BankerPortfolioContainer from "./components/loans/BankerPortfolioContainer";
import ContactsContainer2 from "./components/contacts/ContactsContainer";
import DealDetailsContainer from "./components/deals/DealDetailsContainer";
import DealReviewContainer from "./components/dealReview/DealReviewContainer";
import DocsHeader from "./components/docs/DocsHeader";
import EasternWayContainer from "./components/easternWay/EasternWayContainer";
import FundPortfolioHeader from "./components/fundPortfolio/FundPortfolioHeader";
import FundPortfolioDetailsContainer from "./components/fundPortfolio/FundPortfolioDetailsContainer";
import VerificationRequiredPage from "./components/home/VerificationRequiredPage";
import ResendActivationPage from "./components/home/ResendActivationPage";
import AccntActivationPage from "./components/home/AccntActivationPage";
import HomeContainer from "./components/home/HomeContainer";
import IPPMainDealDetailsContainer from "./components/inProcessPipeline/IPPDealDetailsContainer";
import Login from "./components/login/LoginContainer";
import MBQDealDetailsContainer from "./components/missingQuotesBroker/MQBDealDetailsContainer";
import MyLoansDealDetailsContainer from "./components/loans/MyLoansDealDetailsContainer";
import AllLoansDealDetailsContainer from "./components/loans/AllLoansDealDetailsContainer";
import MQMainDealDetailsContainer from "./components/missingQuotes/MQDealDetailsContainer";
import NotFound from "./components/common/PageNotFound404";
import ErrorPage from "./components/common/ErrorPage";
import { SiteLoader } from "./components/common/Loader";
import PWIPContainer from "./components/deals/DealsContainer";
import PropertyDealDetailsContainer from "./components/myProperties/DealDetailsContainer";
import QuoteFormContainer from "./components/missingQuotes/QuoteFormContainer";
import QuotesMainContainer from "./components/missingQuotes/QuotesMainContainer";
import { RatesPage } from "./components/newRates";
import ResetPasswordPage from "./components/resetPassword/ResetPasswordPage";
import RegisterContainer from "./components/login/RegisterContainer";
import ResetPasswordSuccess from "./components/resetPassword/ResetPasswordSuccess";
import UnsubscribeContainer from "./components/unsubscribe/UnsubscribeContainer";

import CalculatorContainer from "./components/calculators/CalculatorContainer";
import CapRate from "./components/calculators/calculatorPages/CapRate";
import CashOnCash from "./components/calculators/calculatorPages/CashOnCash";
import DebtYield from "./components/calculators/calculatorPages/DebtYield";
// import EnsureLoggedInContainer from './components/common/EnsureLoggedInContainer';
import InterestOnly from "./components/calculators/calculatorPages/InterestOnly";
import IRRCalculator from "./components/calculators/calculatorPages/IRR";
import MaxLoan from "./components/calculators/calculatorPages/MaxLoan";
import PIandDSCR from "./components/calculators/calculatorPages/PIandDSCR";
import ReverseMortgage from "./components/calculators/calculatorPages/ReverseMortgage";
import SimpleCalculator from "./components/calculators/calculatorPages/SimpleCalculator";
import SimpleUnderwriting from "./components/calculators/calculatorPages/SimpleUnderwriting";
import YieldMaintenance from "./components/calculators/calculatorPages/YieldMaintenance";
import UnderwritingModal from "./components/common/UnderwritingModal";

import { storeAuthenticationInfo } from "./actions/authenticationActions";
import "./css/default.scss";

import ReactGA from "react-ga";

import BlendedInterestRate from "./components/calculators/calculatorPages/BlendedInterestRate";
import LoanSyndication from "./components/calculators/calculatorPages/LoanSyndication";
ReactGA.initialize("UA-90466324-1");

const AccountingContainer = lazy(() =>
  import("./components/accounting/AccountingContainer")
);
const AdditionalQuotePage1 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage")
);
const AdditionalQuotePage2 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage2")
);
const AdditionalQuotePage3 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage3")
);
const AdditionalQuotePage4 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage4")
);
const AdditionalQuotePage5 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage5")
);
const AdditionalQuotePage6 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage6")
);
const AdditionalQuotePage7 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage7")
);
const AdditionalQuotePage8 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage8")
);
const AdditionalQuotePage9 = lazy(() =>
  import("./components/missingQuotes/AdditionalQuotePage9")
);
const AddDealNoteContainer = lazy(() =>
  import("./components/dealDetails/AddEditNoteContainer")
);
const EditDealNoteContainer = lazy(() =>
  import("./components/dealDetails/AddEditNoteContainer")
);
const AskQtsSearchContainer = lazy(() =>
  import("./components/QTS/AskQTSSearchContainer")
);
const BankQuoteContainer = lazy(() =>
  import("./components/dealDetails/BankQuotesContainer")
);
const ComingSoonPage = lazy(() => import("./components/common/ComingSoonPage"));
const CompsContainer = lazy(() => import("./components/comps/CompsContainer"));
const CompSVContainer = lazy(() =>
  import("./components/comps/CompSVContainer")
);
const ContactsPage = lazy(() => import("./components/contacts/ContactsPage"));
const ContactDetailContainer = lazy(() =>
  import("./components/contacts/contactDetails/ContactDetailContainer")
);
const CreateNewContactContainer = lazy(() =>
  import("./components/contacts/CreateNewContactContainer")
);
const EditContactContainer = lazy(() =>
  import("./components/contacts/ContactEditContainer")
);
const PwIpContactsPage = lazy(() =>
  import("./components/contacts/PwIpContacts")
);
const StarredContactsPage = lazy(() =>
  import("./components/contacts/StarredContacts")
);
const ClosingClientContacts = lazy(() =>
  import("./components/contacts/ClosingClientContacts")
);
const SearchContacts = lazy(() =>
  import("./components/contacts/SearchContacts")
);
const ResultsContacts = lazy(() =>
  import("./components/contacts/ResultsContacts")
);
const DealContactsContainer = lazy(() =>
  import("./components/dealDetails/DealContactsContainer")
);
const DealDocumentsContainer = lazy(() =>
  import("./components/dealDetails/DealDocumentsContainer")
);
const DealEcalc = lazy(() => import("./components/eCalc/DealDetailsEcalc"));
const DealNotesContainer = lazy(() =>
  import("./components/dealDetails/DealNotesContainer")
);
const DocsContainer = lazy(() => import("./components/docs/DocsContainer"));
const ForgotPasswordPage = lazy(() =>
  import("./components/forgotPassword/ForgotPasswordPage")
);
const FundPortfolioContainer = lazy(() =>
  import("./components/fundPortfolio/FundPortfolioContainer")
);
const FundPortfolioQuoteFormContainer = lazy(() =>
  import("./components/fundPortfolio/QuoteFormContainer")
);
const FundQuotesContainer = lazy(() =>
  import("./components/dealDetails/FundQuotesContainer")
);
const InProcessPipelineContainer = lazy(() =>
  import("./components/inProcessPipeline/InProcessPipelineContainer")
);
const InvestingCriteriaContainer = lazy(() =>
  import("./components/investingCriteria/InvestingCriteriaContainer")
);
const InvestingCriteriaAdd = lazy(() =>
  import("./components/investingCriteria/InvestingCriteriaAddContainer")
);
const InvestingCriteriaEdit = lazy(() =>
  import("./components/investingCriteria/InvestingCriteriaEditContainer")
);
const LendingCriteria = lazy(() =>
  import("./components/lendingCriteria/LendingCriteriaContainer")
);
const LendingCriteriaAdd = lazy(() =>
  import("./components/lendingCriteria/LendingCriteriaAddContainer")
);
const LendingCriteriaEdit = lazy(() =>
  import("./components/lendingCriteria/LendingCriteriaEditContainer")
);
const LendingCriteriaContact = lazy(() =>
  import("./components/lendingCriteria/LendingCriteriaContactContainer")
);
const IPDealContainer = lazy(() =>
  import("./components/deals/IPDealContainer")
);
const PWDealContainer = lazy(() =>
  import("./components/deals/PWDealContainer")
);
const FPWDealContainer = lazy(() =>
  import("./components/deals/FPWDealContainer")
);
const MissingQuotesBrokerPage = lazy(() =>
  import("./components/missingQuotesBroker/missingQuotesBrokerContainer")
);
const MissingQuotesBrokerByBrokerPage = lazy(() =>
  import(
    "./components/missingQuotesBroker/missingQuotesBrokerByBrokerContainer"
  )
);
const MissingQuotesContainer = lazy(() =>
  import("./components/missingQuotes/MissingQuotesContainer")
);
const MyPropertiesContainer = lazy(() =>
  import("./components/myProperties/MyPropertiesContainer")
);
const MyLoansContainer = lazy(() =>
  import("./components/loans/MyLoansContainer")
);
const NewsFeedPage = lazy(() =>
  import("./components/newsFeed/NewsFeedContainer")
);
const AllLoansContainer = lazy(() =>
  import("./components/loans/AllLoansContainer")
);
const ProfileContainer = lazy(() =>
  import("./components/profile/ProfileContainer")
);
const PropertyDetailsContainer = lazy(() =>
  import("./components/dealDetails/PropertyDetailsContainer")
);
const QTSBankResults = lazy(() => import("./components/QTS/QTSBankResults"));
const QTSBrokerResults = lazy(() =>
  import("./components/QTS/QTSBrokerResults")
);
const QTSLendingCriteria = lazy(() =>
  import("./components/QTS/QTSLendingCriteriaContainer")
);
const QTSQuotes = lazy(() => import("./components/QTS/QTSQuotes"));
const QTSRatesResuts = lazy(() => import("./components/QTS/QTSRatesResults"));
const QTSMap = lazy(() => import("./components/QTS/QTSMapContainer"));
const QuoteFormPage = lazy(() =>
  import("./components/missingQuotes/QuoteFormPage")
);
const ReportsContainer = lazy(() =>
  import("./components/reports/ReportsContainer")
);
const ReportsView = lazy(() => import("./components/reports/reportView"));
const SearchDeals = lazy(() =>
  import("./components/deals/SearchDealsContainer")
);
const ServiceProvider = lazy(() =>
  import("./components/ServiceProvider/ServiceProviderContainer")
);
const StatusSummaryContainer = lazy(() =>
  import("./components/dealDetails/StatusSummaryContainer")
);
const SPMainDealDetailsContainer = lazy(() =>
  import("./components/ServiceProvider/SPDealDetailsContainer")
);
const TermSheetQuoteContainer = lazy(() =>
  import("./components/missingQuotes/AwaitingTermSheetContainer")
);
const UnconfirmedDealsContainer = lazy(() =>
  import("./components/deals/UnconfirmedDealsContainer")
);
const UWDealContainer = lazy(() =>
  import("./components/deals/UWDealContainer")
);
const ViolationsContainer = lazy(() =>
  import("./components/dealDetails/ViolationsContainer")
);
const WriteUpContainer = lazy(() =>
  import("./components/dealDetails/WriteUpContainer")
);
const ECalcContainer = lazy(() =>
  import("./components/eCalc/CalculatorContainer")
);
const SaveEcalcSuccess = lazy(() =>
  import("./components/eCalc/SaveEcalcSuccess")
);

const jss = createJSS({
  ...jssPreset(),
  // See "Other HTML Element" at https://material-ui.com/styles/advanced/#insertionpoint
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const store = configureStore();
if (isAuthenticated()) {
  store.dispatch(storeAuthenticationInfo());
  const userData = { ...store.getState().authentication.data };
  mixpanelRegisterPerson(userData);

}

function isAuthenticated() {
  return !!(
    localStorage.getItem("auth_timestamp") && localStorage.getItem("auth_token")
  );
}

function logPageView() {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
}

class LayoutRoute extends React.PureComponent {
  renderLayout = (renderProps) => {
    const { children, component: LayoutView } = this.props;
    return (
      <LayoutView {...renderProps}>
        <Switch>{children}</Switch>
      </LayoutView>
    );
  };
  render() {
    const {
      children: _children,
      component: _component,
      ...routeProps
    } = this.props;
    return <Route {...routeProps} render={this.renderLayout} />;
  }
}

function routeUnconfirmedDeals(path) {
  const {
    pwDeals: { unconfirmed_deal_overdue = false } = {},
  } = store.getState();
  if (path !== "/unconfirmed-deals" && unconfirmed_deal_overdue) {
    return <Redirect to="/unconfirmed-deals?redirected=true" />;
  }
  return false;
}

class AnonRoute extends React.PureComponent {
  render() {
    const routeProps = this.props;
    const { authentication: { token = "" } = {} } = store.getState();
    const auth = !!token;
    return auth ? (
      routeUnconfirmedDeals(routeProps.path) || <Route {...routeProps} />
    ) : (
      <Route {...routeProps} />
    );
  }
}

class AuthLayoutRoute extends LayoutRoute {
  render() {
    const {
      auth,
      children: _children,
      component: _component,
      ...routeProps
    } = this.props;
    return auth ? (
      routeUnconfirmedDeals(routeProps.path) || (
        <Route {...routeProps} render={this.renderLayout} />
      )
    ) : (
      <Redirect to="/login" />
    );
  }
}

class AuthRoute extends React.PureComponent {
  render() {
    const { auth, ...routeProps } = this.props;
    return auth ? (
      routeUnconfirmedDeals(routeProps.path) || <Route {...routeProps} />
    ) : (
      <Redirect to="/login" />
    );
  }
}

class RootComponent extends React.PureComponent {
  state = {
    showUnderwritingModal: false,
  };

  onShowUnderwritingModalClose = () => {
    this.setState({ showUnderwritingModal: false });
  };

  handleDeepLink = (message) => {
    const { deeplink, deepLinkUrl } = message.data;
    if (deeplink) {
      if (window.location.href !== deepLinkUrl) {
        const url = new URL(deepLinkUrl);
        Navigation.redirect(url.pathname + (url.search || ""));
      }
    }
  };

  componentDidMount() {
    //Takes time for injected js to kick in
    setTimeout(() => {
      registerGlobalData();
    }, 2000);
    window.addEventListener("message", this.handleDeepLink);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleDeepLink);
  }

  render() {
    const { auth } = this.props;
    return (
      <Fragment>
        <Router history={Navigation.history}>
          <Suspense fallback={<SiteLoader />}>
            <Switch>
              <AnonRoute path="/error" component={ErrorPage} />
              <AnonRoute exact path="/login" component={Login} />
              <AnonRoute
                exact
                path="/reset-password"
                component={ResetPasswordPage}
              />
              <AnonRoute exact path="/register" component={RegisterContainer} />
              <AnonRoute
                exact
                path="/reset-password-success"
                component={ResetPasswordSuccess}
              />
              <AnonRoute
                exact
                path="/forgot-password"
                component={(props) => <ForgotPasswordPage {...props} />}
              />
              <AnonRoute
                exact
                path="/verify-email"
                component={VerificationRequiredPage}
              />
              <AnonRoute
                exact
                path="/resend-activation"
                component={ResendActivationPage}
              />
              <AnonRoute
                exact
                path="/confirm-account"
                component={AccntActivationPage}
              />
              <AnonRoute
                exact
                path="/activation-success"
                component={ActivationSuccessful}
              />
              <AnonRoute
                exact
                path="/account-activation"
                component={AccountActivationPage}
              />
              <AnonRoute
                exact
                path="/account-activation-new"
                component={AccountActivationPageNew}
              />
              <AnonRoute
                exact
                path="/unsubscribe"
                component={UnsubscribeContainer}
              />
              <LayoutRoute path="/" component={App}>
                <Switch>
                  <AnonRoute exact path="/" component={HomeContainer} />
                  <AnonRoute exact path="/rate-sheet" component={RatesPage} />
                  <AnonRoute
                    exact
                    path="/eastern-education"
                    component={EasternWayContainer}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/news-feed"
                    component={(props) => <NewsFeedPage {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/comps"
                    component={(props) => <CompsContainer {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/comp-sv"
                    component={(props) => <CompSVContainer {...props} />}
                  />
                  <AuthLayoutRoute
                    auth={auth}
                    path="/calculators"
                    component={CalculatorContainer}
                  >
                    <AnonRoute
                      exact
                      path="/calculators/DSCRandBalance"
                      component={PIandDSCR}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/interestOnly"
                      component={InterestOnly}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/capRate"
                      component={CapRate}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/cashOnCash"
                      component={CashOnCash}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/debtYield"
                      component={DebtYield}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/simpleUnderwriting"
                      component={SimpleUnderwriting}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/maxLoan"
                      component={MaxLoan}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/standardCalculator"
                      component={SimpleCalculator}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/yieldMaintenance"
                      component={YieldMaintenance}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/reverseMortgage"
                      component={ReverseMortgage}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/IRR"
                      component={IRRCalculator}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/blendedInterestRate"
                      component={BlendedInterestRate}
                    />
                    <AnonRoute
                      exact
                      path="/calculators/loanSyndication"
                      component={LoanSyndication}
                    />
                    {/* <AnonRoute exact path="/calculators/*" />
                  <AnonRoute exact path="/calculators" /> */}
                    <Redirect
                      from="/calculators"
                      to="/calculators/DSCRandBalance"
                    />
                    <Redirect
                      from="/calculators/*"
                      to="/calculators/DSCRandBalance"
                    />
                  </AuthLayoutRoute>
                  <AuthLayoutRoute
                    auth={auth}
                    path="/e-calc"
                    component={ECalcContainer}
                  >
                    <AnonRoute
                      exact
                      path="/e-calc/calculator"
                      component={ECalcContainer}
                    />
                    <AnonRoute
                      exact
                      path="/e-calc/save-ecalc-success"
                      component={SaveEcalcSuccess}
                    />
                    <Redirect from="/e-calc" to="/e-calc/calculator" />
                  </AuthLayoutRoute>
                  {/* <AuthLayoutRoute
                    auth={auth}
                    path="/accounting"
                    component={AccountingHeader}
                  >
                    <AnonRoute
                      exact
                      path="/accounting/:type"
                      component={(props) => <AccountingContainer {...props} />}
                    />
                  </AuthLayoutRoute> */}

                  <AuthLayoutRoute
                    auth={auth}
                    path="/broker-deals/:type"
                    component={PWIPContainer}
                  >
                    <AnonRoute
                      exact
                      path="/broker-deals/ip"
                      component={(props) => <IPDealContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deals/pw"
                      component={(props) => <PWDealContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deals/fpw"
                      component={(props) => <FPWDealContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deals/uw"
                      component={(props) => <UWDealContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/search-deals"
                    component={(props) => <SearchDeals {...props} />}
                  />

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/contacts/new-contact"
                    component={(props) => (
                      <CreateNewContactContainer {...props} />
                    )}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/contacts/edit/:contactId/:previousPage"
                    component={(props) => <EditContactContainer {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/contacts/edit/:contactId"
                    component={(props) => <EditContactContainer {...props} />}
                  />

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/contacts/details/:id/:previousPage"
                    component={(props) => <ContactDetailContainer {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/contacts/details/:id"
                    component={(props) => <ContactDetailContainer {...props} />}
                  />

                  <AuthLayoutRoute
                    auth={auth}
                    path="/contacts"
                    component={ContactsContainer2}
                  >
                    <AnonRoute
                      exact
                      path="/contacts/contacts/today"
                      component={(props) => <ContactsPage {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/contacts/pw-ip-contacts"
                      component={(props) => <PwIpContactsPage {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/contacts/starred-contacts"
                      component={(props) => <StarredContactsPage {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/contacts/closing-clients-contacts"
                      component={(props) => (
                        <ClosingClientContacts {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/contacts/search"
                      component={(props) => <SearchContacts {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/contacts/search/:name"
                      component={(props) => <ResultsContacts {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/inProcessPipeline"
                    component={(props) => (
                      <InProcessPipelineContainer {...props} />
                    )}
                  />

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/broker-deal/:dealType/:dealId/notes/add"
                    component={(props) => <AddDealNoteContainer {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/broker-deal/:dealType/:dealId/notes/edit/:noteId"
                    component={(props) => <EditDealNoteContainer {...props} />}
                  />

                  <AuthLayoutRoute
                    auth={auth}
                    path="/broker-deal/:dealType/:dealId"
                    component={DealDetailsContainer}
                  >
                    <AnonRoute
                      exact
                      path="/broker-deal/:dealType/:dealId/bank-quotes"
                      component={(props) => <BankQuoteContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deal/:dealType/:dealId/status-summary"
                      component={(props) => (
                        <StatusSummaryContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deal/:dealType/:dealId/property-details"
                      component={(props) => (
                        <PropertyDetailsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deal/:dealType/:dealId/notes"
                      component={(props) => <DealNotesContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deal/:dealType/:dealId/write-up"
                      component={(props) => <WriteUpContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deal/:dealType/:dealId/property-docs"
                      component={(props) => (
                        <DealDocumentsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deal/:dealType/:dealId/ecalc"
                      component={(props) => <DealEcalc {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/broker-deal/:dealType/:dealId/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthLayoutRoute
                    auth={auth}
                    path="/IPPdeal-details/:dealId"
                    component={IPPMainDealDetailsContainer}
                  >
                    <AnonRoute
                      exact
                      path="/IPPdeal-details/:dealId/property-details"
                      component={(props) => (
                        <PropertyDetailsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/IPPdeal-details/:dealId/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthLayoutRoute
                    auth={auth}
                    path="/docs"
                    component={DocsHeader}
                  >
                    <AnonRoute
                      exact
                      path="/docs/:type"
                      component={(props) => <DocsContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/fund-portfolio/:type/:quoteId/quote"
                    component={(props) => (
                      <FundPortfolioQuoteFormContainer {...props} />
                    )}
                  />

                  <AuthLayoutRoute
                    auth={auth}
                    path="/fund-portfolio/:type/:dealId"
                    component={FundPortfolioDetailsContainer}
                  >
                    <AnonRoute
                      exact
                      path="/fund-portfolio/:type/:dealId/property-details"
                      component={(props) => (
                        <PropertyDetailsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/fund-portfolio/:type/:dealId/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthLayoutRoute
                    auth={auth}
                    path="/fund-portfolio"
                    component={FundPortfolioHeader}
                  >
                    <AnonRoute
                      exact
                      path="/fund-portfolio/:type"
                      component={(props) => (
                        <FundPortfolioContainer {...props} />
                      )}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/investing-criteria"
                    component={(props) => (
                      <InvestingCriteriaContainer {...props} />
                    )}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/investing-criteria/add"
                    component={(props) => <InvestingCriteriaAdd {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/investing-criteria/edit/:id"
                    component={(props) => <InvestingCriteriaEdit {...props} />}
                  />

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/lending-criteria"
                    component={(props) => <LendingCriteria {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/lending-criteria/add"
                    component={(props) => <LendingCriteriaAdd {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/lending-criteria/edit/:id"
                    component={(props) => <LendingCriteriaEdit {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/lending-criteria/contact/:contact_id"
                    component={(props) => <LendingCriteriaContact {...props} />}
                  />

                  <AuthLayoutRoute
                    auth={auth}
                    path="/loans"
                    component={BankerPortfolioContainer}
                  >
                    <AnonRoute
                      exact
                      path="/loans/my-loans"
                      component={(props) => <MyLoansContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/loans/all-loans"
                      component={(props) => <AllLoansContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthLayoutRoute
                    auth={auth}
                    path="/my-loans-dd"
                    component={MyLoansDealDetailsContainer}
                  >
                    <AnonRoute
                      exact
                      path="/my-loans-dd/property-details"
                      component={(props) => (
                        <PropertyDetailsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/my-loans-dd/write-up"
                      component={(props) => <WriteUpContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/my-loans-dd/status-summary"
                      component={(props) => (
                        <StatusSummaryContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/my-loans-dd/deal-contacts"
                      component={(props) => (
                        <DealContactsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/my-loans-dd/personal-docs"
                      component={(props) => (
                        <DealDocumentsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/my-loans-dd/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthLayoutRoute
                    auth={auth}
                    path="/all-loans-dd"
                    component={AllLoansDealDetailsContainer}
                  >
                    <AnonRoute
                      exact
                      path="/all-loans-dd/property-details"
                      component={(props) => (
                        <PropertyDetailsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/all-loans-dd/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthLayoutRoute
                    auth={auth}
                    path="/missingQuotes"
                    component={QuotesMainContainer}
                  >
                    <AnonRoute
                      exact
                      path="/missingQuotes/quote"
                      component={(props) => (
                        <MissingQuotesContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/missingQuotes/termSheet"
                      component={(props) => (
                        <TermSheetQuoteContainer {...props} />
                      )}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/missingQuotesBroker"
                    component={(props) => (
                      <MissingQuotesBrokerPage {...props} />
                    )}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/missingQuotesBrokerByBroker/:broker_name/:broker_id"
                    component={(props) => (
                      <MissingQuotesBrokerByBrokerPage {...props} />
                    )}
                  />

                  <AuthLayoutRoute
                    auth={auth}
                    path="/MQB-deal-details/:dealId"
                    component={MBQDealDetailsContainer}
                  >
                    <AnonRoute
                      exact
                      path="/MQB-deal-details/:dealId/bank-quotes"
                      component={(props) => <BankQuoteContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/MQB-deal-details/:dealId/write-up"
                      component={(props) => <WriteUpContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/MQB-deal-details/:dealId/property-docs"
                      component={(props) => (
                        <DealDocumentsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/MQB-deal-details/:dealId/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthLayoutRoute
                    auth={auth}
                    path="/MQdeal-details/:dealId"
                    component={MQMainDealDetailsContainer}
                  >
                    <AnonRoute
                      exact
                      path="/MQdeal-details/:dealId/property-details"
                      component={(props) => (
                        <PropertyDetailsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/MQdeal-details/:dealId/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/profile"
                    component={(props) => <ProfileContainer {...props} />}
                  />

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/properties/:category"
                    component={(props) => <MyPropertiesContainer {...props} />}
                  />

                  <AuthLayoutRoute
                    auth={auth}
                    path="/properties/:dealType/:dealId"
                    component={PropertyDealDetailsContainer}
                  >
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/bank-quotes"
                      component={(props) => <BankQuoteContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/fund-quotes"
                      component={(props) => <FundQuotesContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/status-summary"
                      component={(props) => (
                        <StatusSummaryContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/property-docs"
                      component={(props) => (
                        <DealDocumentsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/ecalc"
                      component={(props) => <DealEcalc {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/property-details"
                      component={(props) => (
                        <PropertyDetailsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/deal-contacts"
                      component={(props) => (
                        <DealContactsContainer {...props} />
                      )}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/personal-docs"
                      component={(props) => <ComingSoonPage {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/properties/:dealType/:dealId/personal-contacts"
                      component={(props) => <ComingSoonPage {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/reports"
                    component={(props) => <ReportsContainer {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/reports/view-report"
                    component={(props) => <ReportsView {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/the-ira-group"
                    component={(props) => <ReportsContainer {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/service-provider"
                    component={(props) => <ServiceProvider {...props} />}
                  />

                  <AuthLayoutRoute
                    auth={auth}
                    path="/SPdeal-details/:dealId/"
                    component={(props) => (
                      <SPMainDealDetailsContainer {...props} />
                    )}
                  >
                    <AnonRoute
                      exact
                      path="/SPdeal-details/:dealId/violations"
                      component={(props) => <ViolationsContainer {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/SPdeal-details/:dealId/property-details"
                      component={(props) => (
                        <PropertyDetailsContainer {...props} />
                      )}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/qts-quotes"
                    component={(props) => <QTSQuotes {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/qts-lending-criteria"
                    component={(props) => <QTSLendingCriteria {...props} />}
                  />

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/qts-search"
                    component={(props) => <AskQtsSearchContainer {...props} />}
                  />

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/qts-banks"
                    component={(props) => <QTSBankResults {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/qts-rates"
                    component={(props) => <QTSRatesResuts {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/qts-brokers"
                    component={(props) => <QTSBrokerResults {...props} />}
                  />
                  <AuthRoute
                    auth={auth}
                    exact
                    path="/qts-map"
                    component={(props) => <QTSMap {...props} />}
                  />

                  <AuthLayoutRoute
                    auth={auth}
                    path="/quote-form/:page/:quote_id/:previousPage"
                    component={QuoteFormContainer}
                  >
                    <AnonRoute
                      exact
                      path="/quote-form/1/:quote_id/:previousPage"
                      component={(props) => <QuoteFormPage {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/2/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage1 {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/3/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage2 {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/4/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage3 {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/5/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage4 {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/6/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage5 {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/7/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage6 {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/8/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage7 {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/9/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage8 {...props} />}
                    />
                    <AnonRoute
                      exact
                      path="/quote-form/10/:quote_id/:previousPage"
                      component={(props) => <AdditionalQuotePage9 {...props} />}
                    />
                  </AuthLayoutRoute>

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/unconfirmed-deals"
                    component={(props) => (
                      <UnconfirmedDealsContainer {...props} />
                    )}
                  />

                  <AuthRoute
                    auth={auth}
                    exact
                    path="/deal-review-filter"
                    component={(props) => <DealReviewContainer {...props} />}
                  />
                  <AuthLayoutRoute
                    auth={auth}
                    path="/deal-review-list"
                    component={(props) => <DealReviewContainer {...props} />}
                  >
                    {/* <AnonRoute
                      exact
                      path="/deal-review-list/:dealId"
                      component={props => <DealReviewContainer {...props} />}
                    /> */}
                  </AuthLayoutRoute>

                  <AnonRoute path="*" component={NotFound} />
                </Switch>
              </LayoutRoute>

              <AnonRoute path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
        <UnderwritingModal
          open={this.state.showUnderwritingModal}
          onClose={this.onShowUnderwritingModalClose}
        />
      </Fragment>
    );
  }
}
const RootComponentConnected = connect((state) => ({
  auth: !!(state.authentication && state.authentication.token),
}))(RootComponent);
// #region This region of code is temporary, not needed with routing kit.
function onLocationChanged(location, action) {
  logPageView();
  mixpanelTrackLoc(`${location.pathname}${location.search}${location.hash}`);

  if (
    !isAuthenticated() &&
    location.pathname !== "/login" &&
    location.pathname !== "/Login"
  ) {
    localStorage.setItem(
      "current_path_redirect",
      location.pathname + (location.search || "")
    );
  }
  Navigation.routeChanged({
    query: querySerializer.parse(location.search),
    location,
  });
}
Navigation.init();
Navigation.history.listen(onLocationChanged);
onLocationChanged(window.location);
// #endregion
render(
    <StylesProvider jss={jss}>
      <ThemeProvider theme={defaultTheme}>
        <Provider store={store}>
            <RootComponentConnected />
        </Provider>
      </ThemeProvider>
    </StylesProvider>,
  document.getElementById("app")
);

/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 */
serviceWorker.register(serviceWorkerConfig);
