import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

import { Navigation } from "../../lib";
import * as dealReviewActions from "../../actions/dealReviewActions";
import {getReportList} from "../../actions/reportsActions";
import encodeQueryData from "../common/encodeQueryData";
import FormattedDatePicker from "../common/FormattedDatePicker";

class DealReviewFilterForm extends React.PureComponent {
  state = {
    filters: {
      city: "",
      dealTypes: [],
      expectedCLStartDate: "",
      expectedCLEndDate: "",
      expectedIPStartDate: "",
      expectedIPEndDate: "",
      inContract: [],
      ipl: ["above"],
      lastReviewedBefore: "",
      loanAmountMin: "",
      loanAmountMax: "",
      loanTypes: [],
      propertyTypes: [],
      ratings: [0, 1, 2, 3],
      states: [],
      statusChangeStartDate: "",
      statusChangeEndDate: "",
      statuses: [],
      originator: false,
      users: [],
    },
    showAdvancedFilters: false,
  };

  initialFilters = _cloneDeep(this.state.filters);

  componentDidMount() {
    const {
      getDealReviewUsers,
      getPropertyTypes,
      getDealTypes,
      getLoanTypes,
      getStates,
    } = this.props.dealReviewActions;
    this.props.getReportList();
    getDealReviewUsers();
    getPropertyTypes();
    getDealTypes();
    getLoanTypes();
    getStates();
  }

  handleFilterChange = (name, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
  };

  handleFilterArrayChange = (name, value) => {
    let array = this.state.filters[name];
    if (array.includes(value)) {
      array = array.filter((arrayValue) => arrayValue !== value);
    } else {
      array.push(value);
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: array,
      },
    });
  };

  handleOriginatorFilter = (e) => {
    const value = e.target.checked == true ? 1 : 0;
    this.setState({
      filters: {
        ...this.state.filters,
        originator: Number(value) ,
      },
    });
  }

  submitFilterSearch = () => {
    const {
      filters: {
        city,
        expectedIPStartDate,
        expectedIPEndDate,
        expectedCLStartDate,
        expectedCLEndDate,
        inContract,
        ipl,
        lastReviewedBefore,
        loanAmountMin,
        loanAmountMax,
        ratings,
        statusChangeStartDate,
        statusChangeEndDate,
        originator,
        statuses,
        users,
      },
    } = this.state;
    //need to manipulate these properties before submitting
    let {
      filters: { dealTypes, loanTypes, propertyTypes, states },
    } = this.state;
    let userIDs = users
      .map((user) => {
        return user.data.member_id;
      })
      .filter((id) => id !== undefined);
    let userNames = users
      .map((user) => {
        return user.data.full_name;
      })
      .filter((id) => id !== undefined);
    let userGroups = users
      .map((user) => {
        return user.data.value;
      })
      .filter((group) => group !== undefined);
    propertyTypes = propertyTypes.map((propertyType) => {
      return propertyType.data.list_type_id;
    });
    dealTypes = dealTypes.map((dealType) => {
      return dealType.data.value;
    });
    loanTypes = loanTypes.map((loanType) => {
      return loanType.data.value;
    });
    states = states.map((state) => {
      return state.data.abb;
    });
    console.log("originator",originator);
    let data = {
      member_id: userIDs,
      member_name: userNames,
      member_group: userGroups,
      deal_status: statuses,
      last_reviewed_before: lastReviewedBefore,
      property_type: propertyTypes,
      deal_type: dealTypes,
      loan_type: loanTypes,
      property_city: city,
      property_state: states,
      status_change_before: statusChangeEndDate,
      status_change_after: statusChangeStartDate,
      loan_amount_min: loanAmountMin,
      loan_amount_max: loanAmountMax,
      in_contract: inContract,
      originator: originator
    };
    if (statuses.includes(1)) {
      data.deal_rating = ratings;
      data.expected_ip_date_before = expectedIPEndDate;
      data.expected_ip_date_after = expectedIPStartDate;
    }
    if (statuses.includes(2)) {
      data.inprocess_line = ipl;
      data.expected_cl_date_before = expectedCLEndDate;
      data.expected_cl_date_after = expectedCLStartDate;
    }
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    Navigation.go(`/deal-review-list?${encodeQueryData(data)}`);
  };

  submitCustomReport = async (option) => {
    await this.props.dealReviewActions.getDealIds(option);
    this.props.dealReview.dealIds.length &&
    Navigation.go(`/deal-review-list?member_id=&member_name=&member_group=&deal_id=${this.props.dealReview.dealIds}`);
  }

  render() {
    const {
      showAdvancedFilters,
      filters: { inContract, ipl, ratings, statuses ,originator},
    } = this.state;
    const {
      dealTypes,
      loanTypes,
      propertyTypes,
      states,
      users,
    } = this.props.dealReview;
    const { listReports } = this.props.reports;
    let dirty = !_isEqual(this.state.filters, this.initialFilters);
    return (
      <div className="card deal-review-filter-form-card">
        {/* TODO: remove from mobile when i put deal review nav title in header */}
        <p className="xl-text bold-text mb-24">Deal review filter</p>
        <div className="deal-review-filter-form">
        <div className="two-column-grid mb-24">
            <Select
              className="deal-review-filter-form-select"
              classNamePrefix="deal-review-filter-form-select"
              isMulti={true}
              isSearchable={true}
              onChange={(option) => this.handleFilterChange("users", option)}
              options={
                users &&
                users.map((user) => ({
                  key: user.full_name ? user.full_name : user.name,
                  value: user.full_name ? user.full_name : user.name,
                  label: user.full_name ? user.full_name : user.name,
                  data: user,
                }))
              }
              placeholder="User (or users)"
            />
            <div className="deal-review-filter-form-checkbox-btns">
              <input
                type="checkbox"
                name="originator"
                id="originator"
                checked={originator}
                onChange={(e) => this.handleOriginatorFilter(e)}
              />
              <label htmlFor="originator">Originator</label>
            </div>
          </div>
          <div className="two-column-grid">
            <div>
              <label className="xs-text bold-text block mb-8">Status</label>
              <div className="deal-review-filter-form-checkbox-btns">
                <div>
                  <input
                    type="checkbox"
                    name="status"
                    id="status-fpw"
                    checked={statuses.includes(10)}
                    onChange={() => this.handleFilterArrayChange("statuses", 10)}
                  />
                  <label htmlFor="status-fpw">FPW</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="status"
                    id="status-pw"
                    checked={statuses.includes(1)}
                    onChange={() => this.handleFilterArrayChange("statuses", 1)}
                  />
                  <label htmlFor="status-pw">PW</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="status"
                    id="status-ip"
                    checked={statuses.includes(2)}
                    onChange={() => this.handleFilterArrayChange("statuses", 2)}
                  />
                  <label htmlFor="status-ip">IP</label>
                </div>
              </div>
            </div>
            <div>
              <label className="xs-text bold-text block mb-8">
                Last Reviewed Before
              </label>
              <FormattedDatePicker
                placeholderClassName={"date-placeholder"}
                update={(value) =>
                  this.handleFilterChange("lastReviewedBefore", value)
                }
              />
            </div>
            {statuses.includes(1) || statuses.includes(10) ? (
              <React.Fragment>
                <div>
                  <label className="xs-text bold-text block mb-8">Rating</label>
                  <div className="deal-review-filter-form-checkbox-btns">
                    <div>
                      <input
                        type="checkbox"
                        name="rating"
                        id="rating-zero"
                        checked={ratings.includes(0)}
                        onChange={() =>
                          this.handleFilterArrayChange("ratings", 0)
                        }
                      />
                      <label htmlFor="rating-zero">0</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="rating"
                        id="rating-one"
                        checked={ratings.includes(1)}
                        onChange={() =>
                          this.handleFilterArrayChange("ratings", 1)
                        }
                      />
                      <label htmlFor="rating-one">1</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="rating"
                        id="rating-two"
                        checked={ratings.includes(2)}
                        onChange={() =>
                          this.handleFilterArrayChange("ratings", 2)
                        }
                      />
                      <label htmlFor="rating-two">2</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="rating"
                        id="rating-three"
                        checked={ratings.includes(3)}
                        onChange={() =>
                          this.handleFilterArrayChange("ratings", 3)
                        }
                      />
                      <label htmlFor="rating-three">3</label>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    Expected IP
                  </label>
                  <div className="flex">
                    <FormattedDatePicker
                      className="mr-8 relative"
                      placeholderClassName={
                        "date-placeholder start-date-placeholder"
                      }
                      update={(value) =>
                        this.handleFilterChange("expectedIPStartDate", value)
                      }
                    />
                    <FormattedDatePicker
                      className="ml-8"
                      placeholderClassName={
                        "date-placeholder end-date-placeholder"
                      }
                      update={(value) =>
                        this.handleFilterChange("expectedIPEndDate", value)
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            {statuses.includes(2) ? (
              <React.Fragment>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    In Process Line
                  </label>
                  <div className="deal-review-filter-form-checkbox-btns">
                    <div>
                      <input
                        type="checkbox"
                        name="ipl"
                        id="ipl-above"
                        checked={ipl.includes("above")}
                        onChange={() =>
                          this.handleFilterArrayChange("ipl", "above")
                        }
                      />
                      <label htmlFor="ipl-above">Above</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="ipl"
                        id="ipl-below"
                        checked={ipl.includes("below")}
                        onChange={() =>
                          this.handleFilterArrayChange("ipl", "below")
                        }
                      />
                      <label htmlFor="ipl-below">Below</label>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    Expected CL
                  </label>
                  <div className="flex">
                    <FormattedDatePicker
                      className="mr-8"
                      placeholderClassName={
                        "date-placeholder start-date-placeholder"
                      }
                      update={(value) =>
                        this.handleFilterChange("expectedCLStartDate", value)
                      }
                    />
                    <FormattedDatePicker
                      className="ml-8"
                      placeholderClassName={
                        "date-placeholder end-date-placeholder"
                      }
                      update={(value) =>
                        this.handleFilterChange("expectedCLEndDate", value)
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            {showAdvancedFilters ? (
              <React.Fragment>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    Property Type
                  </label>
                  <Select
                    className="deal-review-filter-form-select"
                    classNamePrefix="deal-review-filter-form-select"
                    isMulti={true}
                    isSearchable={true}
                    onChange={(option) =>
                      this.handleFilterChange("propertyTypes", option)
                    }
                    options={
                      propertyTypes &&
                      propertyTypes.map((propertyType) => ({
                        key: propertyType.value,
                        value: propertyType.value,
                        label: propertyType.value,
                        data: propertyType,
                      }))
                    }
                    placeholder="Select"
                  />
                </div>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    Deal Type
                  </label>
                  <Select
                    className="deal-review-filter-form-select"
                    classNamePrefix="deal-review-filter-form-select"
                    isMulti={true}
                    isSearchable={true}
                    onChange={(option) =>
                      this.handleFilterChange("dealTypes", option)
                    }
                    options={
                      dealTypes &&
                      dealTypes.map((dealType) => ({
                        key: dealType.value,
                        value: dealType.value,
                        label: dealType.value,
                        data: dealType,
                      }))
                    }
                    placeholder="Select"
                  />
                </div>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    Loan Type
                  </label>
                  <Select
                    className="deal-review-filter-form-select"
                    classNamePrefix="deal-review-filter-form-select"
                    isMulti={true}
                    isSearchable={true}
                    onChange={(option) =>
                      this.handleFilterChange("loanTypes", option)
                    }
                    options={
                      loanTypes &&
                      loanTypes.map((loanType) => ({
                        key: loanType.value,
                        value: loanType.value,
                        label: loanType.value,
                        data: loanType,
                      }))
                    }
                    placeholder="Select"
                  />
                </div>
                <div className="flex">
                  <div className="mr-8">
                    <label className="xs-text bold-text block mb-8">City</label>
                    <input
                      type="text"
                      className="deal-review-filter-form-input"
                      onChange={(e) =>
                        this.handleFilterChange("city", e.target.value)
                      }
                    />
                  </div>
                  <div className="ml-8 full-width">
                    <label className="xs-text bold-text block mb-8">
                      State
                    </label>
                    <Select
                      className="deal-review-filter-form-select"
                      classNamePrefix="deal-review-filter-form-select"
                      isMulti={true}
                      isSearchable={true}
                      onChange={(option) =>
                        this.handleFilterChange("states", option)
                      }
                      options={
                        states &&
                        states.map((state) => ({
                          key: state.state,
                          value: state.state,
                          label: state.state,
                          data: state,
                        }))
                      }
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    Status Change
                  </label>
                  <div className="flex">
                    <FormattedDatePicker
                      className="mr-8"
                      placeholderClassName={
                        "date-placeholder start-date-placeholder"
                      }
                      update={(value) =>
                        this.handleFilterChange("statusChangeStartDate", value)
                      }
                    />
                    <FormattedDatePicker
                      className="ml-8"
                      placeholderClassName={
                        "date-placeholder end-date-placeholder"
                      }
                      update={(value) =>
                        this.handleFilterChange("statusChangeEndDate", value)
                      }
                    />
                  </div>
                </div>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    In Contract
                  </label>
                  <div className="deal-review-filter-form-checkbox-btns">
                    <div>
                      <input
                        type="checkbox"
                        name="contract"
                        id="contract-yes"
                        checked={inContract.includes(1)}
                        onChange={() =>
                          this.handleFilterArrayChange("inContract", 1)
                        }
                      />
                      <label htmlFor="contract-yes">Yes</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="contract"
                        id="contract-no"
                        checked={inContract.includes(2)}
                        onChange={() =>
                          this.handleFilterArrayChange("inContract", 2)
                        }
                      />
                      <label htmlFor="contract-no">No</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="contract"
                        id="contract-blank"
                        checked={inContract.includes(0)}
                        onChange={() =>
                          this.handleFilterArrayChange("inContract", 0)
                        }
                      />
                      <label htmlFor="contract-blank">Blank</label>
                    </div>
                  </div>
                </div>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    Loan Amount
                  </label>
                  <div className="flex">
                    <input
                      type="number"
                      className="deal-review-filter-form-input mr-8 column-half"
                      onChange={(e) =>
                        this.handleFilterChange("loanAmountMin", e.target.value)
                      }
                      placeholder="Min"
                    />
                    <input
                      type="number"
                      className="deal-review-filter-form-input ml-8 column-half"
                      onChange={(e) =>
                        this.handleFilterChange("loanAmountMax", e.target.value)
                      }
                      placeholder="Max"
                    />
                  </div>
                </div>
                <div>
                  <label className="xs-text bold-text block mb-8">
                    Custom Reports
                  </label>
                  <Select
                    className="deal-review-filter-form-select"
                    classNamePrefix="deal-review-filter-form-select"
                    isSearchable={true}
                    onChange={this.submitCustomReport}
                    options={
                      listReports &&
                      listReports.map((report) => ({
                        key: report.sr_id,
                        value: report.sr_id,
                        label: report.title,
                        data: report,
                      }))
                    }
                    placeholder="Select"
                  />
                </div>
              </React.Fragment>
            ) : null}
          </div>
          <div className="deal-review-filter-form-footer flex flex-center flex-space-justify mt-48">
            <p
              className="uppercase-text bold-text accent-color-text link-text"
              onClick={() =>
                this.setState({
                  showAdvancedFilters: !this.state.showAdvancedFilters,
                })
              }
            >
              {showAdvancedFilters ? "Show less filters" : "Show more filters"}
            </p>
            {/* TODO: change to a loader on click */}
            <button
              className="btn btn-accent btn-large uppercase-text"
              onClick={() => this.submitFilterSearch()}
              disabled={!dirty}
            >
              View Results
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dealReview: state.dealReview,
    reports: state.reports
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dealReviewActions: bindActionCreators(dealReviewActions, dispatch),
    getReportList: bindActionCreators(getReportList, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealReviewFilterForm);
