import * as types from "../actions/actionTypes";

const initialState = {
  bankQuotes: {
    totalPages: 1,
    banks: [],
  },
  qtsRates: [],
  brokerDeals: [],
  propertyTypes: [],
  lendingTypeList: [],
  propertyTypeList: [],
  lendingList: [],
  mapData: {},
  contact: {},
  quotes: {},
  mapCenter: {},
  noContact: false,
  loadingDeals: false,
  loadingQuotes: false,
  loadingRates: false,
  loadingContact: false,
  loadingLC: false,
  loadingMap: false,
  noDeals: false,
  noQuotes: false,
  noMapData: false,
  address: "",
  loanAmount: "",
  toast: {
    show: false,
    success: true,
    message: "",
  },
};

export default function qtsReducer(state = initialState, action) {
  switch (action.type) {
    case types.NO_DEAL_DATA:
      return Object.assign({}, state, { noDeals: true });
    case types.NO_QUOTE_DATA:
      return Object.assign({}, state, { noQuotes: true, loadingQuotes: false });
    case types.NO_RATE_DATA:
      return Object.assign({}, state, { noQuotes: true, loadingRates: false });
    case types.NO_DATA:
      return Object.assign({}, state, { noQuotes: true });
    case types.LOADING_QUOTES:
      return Object.assign({}, state, {
        loadingQuotes: true,
        noQuotes: false,
        bankQuotes: initialState.bankQuotes,
        quotes: {},
      });
     case types.LOADING_RATES:
      return Object.assign({}, state, {
        loadingRates: true,
        noRates: false,
        qtsRates: initialState.qtsRates
      });
    case types.LOADING_DEALS:
      return Object.assign({}, state, {
        loadingDeals: true,
        noDeals: false,
        brokerDeals: [],
      });
    case types.LOAD_QTS_BANK_QUOTES:
      return Object.assign({}, state, {
        bankQuotes: { totalPages: action.data.pages, banks: action.data.banks },
        loadingQuotes: false,
        noQuotes: false,
      });
    case types.LOAD_QTS_RATES:
      return Object.assign({}, state, {
        qtsRates: action.data ,
        loadingRates: false,
        noRates: false,
      });
    case types.LOAD_QTS_BANK_DEALS_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });

    case types.LOAD_QTS_BROKER_DEALS:
      return Object.assign({}, state, {
        brokerDeals: action.data,
        loadingDeals: false,
        noDeals: false,
      });
    case types.LOAD_QTS_BROKER_DEALS_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });

    case types.LOADING_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: {},
        loadingContact: true,
        noContact: false,
      });
    case types.LOAD_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
        loadingContact: false,
      });
    case types.NO_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: {},
        noContact: true,
        loadingContact: false,
      });
    case types.LOAD_QTS_QUOTES:
      return Object.assign({}, state, {
        quotes: action.data,
        noQuotes: false,
        loadingQuotes: false,
      });
    case types.LOAD_QTS_QUOTES_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
        loadingQuotes: false,
      });
   case types.LOAD_QTS_RATES_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
        loadingRates: false,
      });
    case types.SAVE_FORM:
      return Object.assign({}, state, {
        propertyTypes: action.propertyType,
        address: action.address,
        loanAmount: action.loanAmount,
      });
    case types.UPDATE_ERROR_TOAST:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });
    case types.CLOSE_ASK_QTS_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, success: true, message: "" },
      });

    case types.LOADING_QTS_MAP:
      return Object.assign({}, state, { loadingMap: true });
    case types.LOAD_QTS_MAP_DATA:
      return Object.assign({}, state, {
        loadingMap: false,
        mapData: action.data,
        mapCenter: action.center,
      });
    case types.NO_QTS_MAP_DATA:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
        loadingMap: false,
        mapData: {},
        mapCenter: action.center,
      });

    case types.LOADING_PARAM_LIST:
      return Object.assign({}, state, { lendingList: [], loadingLC: true });
    case types.LOAD_PARAM_LIST:
      return Object.assign({}, state, {
        lendingList: action.lendingList,
        loadingLC: false,
      });
    case types.LOAD_PARAM_LIST_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });
    case types.LOAD_LENDING_TYPES:
      return Object.assign({}, state, { lendingTypeList: action.data });
    case types.LOAD_PROPERTY_TYPE_LIST:
      return Object.assign({}, state, { propertyTypeList: action.data });
    default:
      return state;
  }
}
